//CSS
import './App.scss';

//External Components
import React from "react";
import {
    BrowserRouter as Router, Switch
} from "react-router-dom";


//Context
import { DataProvider } from "./dataContext";

//Routes
import PrivateRoute from "./Routes/PrivateRoute";
import PublicRoutes from "./Routes/PublicRoutes";

//Pages
import Login from "./Pages/Login";
import Creation from "./Pages/Creation";
import Dashboard from "./Pages/Dashboard";
import Map from './Pages/Map';
import error404 from "./Pages/404";
import Planning from "./Pages/Planning";
import Reset from "./Pages/Reset";
import ResetPassword from "./Pages/ResetPassword";
import Clients from "./Pages/Clients";
import Concierges from "./Pages/Concierges";
import Users from "./Pages/Users";
import Providers from "./Pages/Providers";
import Billing from "./Pages/Billing";
import Archives from './Pages/Archives';
import Gestion from './Pages/Gestion';
import Secteurs from './Pages/Secteurs';

//Modal
import ErrorModal from "./Components/Modals/ErrorModal";

//Base Constant
const baseURL = document.getElementsByTagName('base')[0].getAttribute('href');

function App() {
    return (
        <DataProvider>
            <Router forceRefresh={false}
                basename={baseURL}>
                <div id={"app"}>
                    <Switch>
                        <PublicRoutes path={'/login'} exact component={Login} />
                        <PublicRoutes path={'/creation/:token'} exact component={Creation} />
                        <PublicRoutes path={'/reset'} exact component={Reset} />
                        <PublicRoutes path={'/reset/:token'} exact component={ResetPassword} />
                        <PrivateRoute path={'/'} exact component={Dashboard} />
                        <PrivateRoute path={'/map'} exact component={Map} />
                        <PrivateRoute path={'/clients'} exact component={Clients} />
                        <PrivateRoute path={'/concierges'} exact component={Concierges} />
                        <PrivateRoute path={'/team'} exact component={Users} />
                        <PrivateRoute path={'/billing'} exact component={Billing} />
                        <PrivateRoute path={'/providers'} exact component={Providers} />
                        <PrivateRoute path={'/planning'} exact component={Planning} />
                        <PrivateRoute path={'/archives'} exact component={Archives} />
                        <PrivateRoute path={'/gestion'} exact component={Gestion} />
                        <PrivateRoute path={'/secteurs'} exact component={Secteurs} />
                        <PrivateRoute component={error404} />
                    </Switch>
                </div>
            </Router>
            <ErrorModal />
        </DataProvider>
    );
}

export default App;
