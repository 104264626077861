import React from 'react'

// Components
import SendButton from "../../Button/SendButton";

// Icons
import {BsFillTrashFill, BsPlus} from "react-icons/all";

export default function ClientContactAdd(props) {
    const [list, setList] = React.useState(props.item?.contacts ? props.item.contacts : [])
        , [, r] = React.useState({})

    const reload = () => {
        r({})
    }

    const addElement = () => {
        const listTemp = list
        listTemp.push({
            title: "",
            phone: "",
            email: "",
            description: ""
        })
        reload()
        setList(listTemp)
    }

    const deleteElement = (index) => {
        const listTemp = list
        listTemp.splice(index, 1)
        reload()
        setList(listTemp)
    }

    const handleChange = (index, name, value) => {
        const listTemp = list
        listTemp[index][name] = value
        reload()
        setList(listTemp)
    }

    return (
        <form onSubmit={(e) => props.submit(e, list)}>
            {list.map((contact, index) => {
                return (
                    <div className={"column"}>
                        <hr/>
                        <button className={"btn btn-danger"} type={"button"}
                                onClick={() => deleteElement()}>Supprimer <BsFillTrashFill/></button>
                        <div className="col-6 col-md-12">
                            <div className={"mb-3"}>
                                <label htmlFor={"contacts.title"} className="form-label">Nom</label>
                                <input type={"text"} className="form-control"
                                       id={"title"} name={"title"}
                                       defaultValue={contact.title}
                                       onChange={(e) => {
                                           handleChange(index, e.target.name, e.target.value)
                                       }}
                                       aria-describedby="end" required/>
                            </div>
                        </div>
                        <div className="col-6 col-md-12">
                            <div className={"mb-3"}>
                                <label htmlFor={"phone"} className="form-label">Téléphone</label>
                                <input type="phone" className="form-control" name={"phone"}
                                       defaultValue={contact.phone}
                                       onChange={(e) => {
                                           handleChange(index, e.target.name, e.target.value)
                                       }}
                                       aria-describedby="end"/>
                            </div>
                        </div>

                        <div className="col-6 col-md-12">
                            <div className={"mb-3"}>
                                <label htmlFor={"email"} className="form-label">Email</label>
                                <input type="email" className="form-control"
                                       name={"email"}
                                       defaultValue={contact.email}
                                       onChange={(e) => {
                                           handleChange(index, e.target.name, e.target.value)
                                       }}
                                       aria-describedby="end"/>
                            </div>
                        </div>
                        <div className="col-6 col-md-12">
                            <div className={"mb-3"}>
                                <label htmlFor={"description"} className="form-label">Description</label>
                                <textarea name={"description"} id={"contacts[" + index + "].description"} cols="80" rows="5"
                                          placeholder="Qui est ce contacte et dans quel cas doit-on le contacter ? "
                                          defaultValue={contact.description}
                                          onChange={(e) => {
                                              handleChange(index, e.target.name, e.target.value)
                                          }}
                                          className="form-control"
                                          aria-describedby="end"/>
                            </div>
                        </div>
                    </div>
                )
            })}

            <div className="col-6 col-md-12">
                <div className={"mb-3"}>
                    <button className={"btn w-100"} type={"button"} onClick={() => {
                        addElement()
                    }}> Ajouter un contact <BsPlus/>
                    </button>
                </div>
            </div>
            <div className={"row"}>
                <div className="mb-3 col-12">
                    <SendButton type={"submit"} loading={props.loading}>Envoyer</SendButton>
                </div>
            </div>
        </form>
    )
}