import React from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
const DataContext = React.createContext();

// http://127.0.0.1:3010

let burl = "https://api2.mypacome-manager.fr"

// burl = "https://api2.mypacome-manager.fr"
// burl = "http://localhost:3010"

//burl =  "https://api2.mypacome-manager.fr" "https://api.sandbox.mypacome-manager.fr" "https://localhost:3000"

export class DataProvider extends React.Component {
    state = {
        user: {},
        token: null,
        data: {},
        error: {},
        loading: false,
    }
    header = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    headerAuth = () => {
        return {
            headers: {
                "Content-Type": "application/json",
                "token": this.state.token
            }
        }
    }
    headerAuthFiles = () => {
        return {
            headers: {
                "Content-Type": "multipart/form-data",
                "token": this.state.token
            }
        }
    }

    headerAuthFilesTypeless = () => {
        return {
            headers: {
                "token": this.state.token
            }
        }
    }

    headerAuthFiles2 = () => {
        return {
            headers: {
                "Content-Type": "application/json",
                "token": this.state.token
            }
        }
    }

    setUser = async (user, token) => {
        return new Promise((resolve, reject) => {
            this.setState({ user: user, token: token }, (err) => {
                if (err) reject(err)
                else resolve()
            })
        })
    }
    setData = (data) => {
        return new Promise(((resolve, reject) => {
            const people = []
            for (const key in data.people) {
                for (const item in data.people[key]) {
                    const itemRefined = data.people[key][item]
                    itemRefined.type = key
                    people.push(itemRefined)
                }
            }
            this.setState({ data: { people: data.people, list: people } }, (err) => {
                if (err) {
                    this.setError({ message: err })
                        .then(() => {
                            return reject(err)
                        })
                        .catch((err2) => {
                            return reject(err2)
                        })

                } else {
                    return resolve()
                }
            })
        }))
    }
    addData = (data) => {
        return new Promise(((resolve, reject) => {
            const newData = this.state.data
            for (const key in data) {
                for (const item in data[key]) {
                    const itemRefined = data[key][item]
                    itemRefined.type = key

                    //Check if element already exist, if so delete it
                    newData.list = newData.list.filter((i) => {
                        return !(
                            i.email === itemRefined.email
                            && i.type === itemRefined.type
                            && (!!itemRefined.id ? i.id === itemRefined.id : true)
                        )
                    })
                    newData.people[key] = newData.people[key].filter((i) => {
                        return !(
                            i.email === itemRefined.email
                            && (!!itemRefined.id ? i.id === itemRefined.id : true)
                        )
                    })

                    //Push new element
                    newData.list.push(itemRefined)
                    newData.people[key].push(data[key][item])
                }
            }
            this.setState({ data: newData }, (err) => {
                if (err) {
                    this.setError({ message: err })
                        .then(() => {
                            return reject(err)
                        })
                        .catch((err2) => {
                            return reject(err2)
                        })

                } else {
                    return resolve()
                }
            })
        }))
    }
    setError = (data) => {
        return new Promise((resolve, reject) => {
            this.setState({ error: data }, (err) => {
                if (err) reject(err)
                else resolve()
            })
        })
    }
    logout = () => {
        Cookies.remove('mypacome-PM', { path: '/' })
        this.setState({ user: {}, token: '' })
    }
    isLogged = () => {
        return !!this.state.token
    }
    findInterventionPeople = (id) => {
        let user = this.state.data.people?.Concierge.find(el => el.id === id)
        if (!user) user = this.state.data.people?.Equipe.find(el => el.id === id)
        if (!user) user = { name: "Error", lastName: "NotFound" }
        return user
    }
    getBurl = () => {
        return burl
    }
    errorHandler = (err) => {
        let errorList = ''

        if (err.response?.data.error) {
            console.log(err.response?.data.error)
            errorList = "\n" + errorRefiner(err.response.data.error).join(' \n,')
        }
        if (err.response?.status === 401) {
            errorList = " Problème d'authentification, veillez vous reconnecter."
            this.logout()
        }
        if (err.message === "Network Error") {
            errorList = " Serveur injoignable, veillez contacter l'administrateur du serveur."
            this.logout()
        }
        const message = errorList ? errorList : "veuillez verifier que vous avez bien remplis le formulaire."

        this.setError({
            level: 'warning',
            message: 'Le serveur indique une erreur dans votre demande :\n ' + message,
            btn1Text: 'Revenir',
            btn2hidden: true
        })
    }
    apiReducer = async (action, payload, option) => {
        return new Promise((resolve, reject) => {
            let isAnAction = false

            //USERS

            if (action === 'login') {
                isAnAction = true
                axios.post(burl + '/user/login', payload)
                    .then((response) => {
                        if (!response.data.user || !response.data.token) return reject('Misssing information from server')
                        let user = response.data.user
                        let token = response.data.token
                        this.setUser(response.data.user, response.data.token)
                            .then((response) => {
                                if (option.remember) {
                                    Cookies.set('mypacome-PM', JSON.stringify({
                                        user: user,
                                        token: token
                                    }), { path: '/' })
                                    this.apiReducer('fetchData')
                                        .then(() => {
                                            return resolve()
                                        })
                                        .catch((err) => {
                                            this.setData({ message: err })
                                                .then(() => {
                                                    return reject(err)
                                                })
                                                .catch(() => {
                                                    return reject(err)
                                                })
                                        })
                                } else {
                                    Cookies.set('mypacome-PM', JSON.stringify({
                                        user: user,
                                        token: token
                                    }), { expires: 1, path: '/' })
                                    return resolve()
                                }
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })

            }
            if (action === 'creation') {
                isAnAction = true
                axios.post(burl + '/user/creation/' + option.token, payload)
                    .then(() => {
                        return resolve()
                    })
                    .catch((err) => {
                        return reject(err)
                    })
            }
            if (action === 'reset') {
                isAnAction = true
                axios.post(burl + '/user/reset', payload)
                    .then((response) => {
                        return resolve()
                    })
                    .catch((err) => {
                        return reject(err)
                    })
            }
            if (action === 'resetPassword') {
                isAnAction = true
                axios.post(burl + '/user/reset/' + option.token, payload)
                    .then(() => {
                        return resolve()
                    })
                    .catch((err) => {
                        return reject(err)
                    })
            }
            if (action === 'fetchData') {
                isAnAction = true
                this.setState({ loading: true })
                axios.get(burl + '/user/all', {
                    ...this.headerAuth()
                }).then((result) => {
                    this.setData({ people: result.data })
                        .then(() => {
                            this.setState({ loading: false }, () => {
                                return resolve()
                            })
                        })
                        .catch((err) => {
                            this.setState({ loading: false }, () => {
                                return reject(err)
                            })
                        })

                })
                    .catch((err) => {
                        console.log(err.message)
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            //CLIENT

            //      TRANSACTIONAL
            if (action === 'addClient') {
                isAnAction = true
                axios.post(burl + '/client/', payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande, veuillez verifier que vous avez bien remplis le formulaire',
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }
            if (action === 'modifyClient') {
                isAnAction = true
                axios.put(burl + '/client/' + option.email, payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        console.log(err)
                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande :\n ' + err.message,
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }
            //      SUBSCRIPTION
            if (action === 'addSubscription') {
                isAnAction = true
                axios.post(
                    burl + '/client/subscription/' + option.email,
                    payload,
                    this.headerAuth()
                )
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        console.log(err)
                        this.errorHandler(err)
                        return reject(err)
                    })
            }
            if (action === 'modifySubscription') {
                isAnAction = true
                axios.put(
                    burl + '/client/subscription/' + option.email,
                    payload,
                    this.headerAuth()
                )
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }
            //      PROPERTY
            if (action === 'addProperty') {
                isAnAction = true
                axios.post(burl + '/client/properties/', payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                console.log(err)
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        console.log(err)
                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande, veuillez verifier que vous avez bien remplis le formulaire',
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }
            if (action === 'modifyProperty') {
                isAnAction = true
                if (payload.guide) {
                    const fd = new FormData()
                    fd.append('guide', payload.guide)
                    fd.append("payload", payload)
                    payload = fd
                }
                axios.put(burl + '/client/properties/' + option.name, payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                console.log(err)
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        console.log(err)
                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande, veuillez verifier que vous avez bien remplis le formulaire',
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }
            //      HOMEVIEW
            if (action === 'addHomeView') {
                isAnAction = true
                axios.post(burl + '/client/homeview/', payload, this.headerAuthFiles())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }
            //      HOMEVIEWNEW
            if (action === 'addHomeViewNew') {
                isAnAction = true
                axios.post(burl + '/client/homeviewnew/', payload, this.headerAuthFiles2())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }
            //      BOOKING
            if (action === 'addBooking') {
                isAnAction = true
                axios.post(burl + '/client/properties/' + option.name, payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande, veuillez verifier que vous avez bien remplis le formulaire',
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }
            if (action === 'addBookingInterventions') {
                isAnAction = true
                axios.post(burl + '/client/bookings/interventions/', payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        let errorList = ''
                        if (err.response?.data.error) {
                            errorList = "\n" + errorRefiner(err.response.data.error).join(' \n,')
                        }
                        const message = errorList ? errorList : "veuillez verifier que vous avez bien remplis le formulaire."

                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande :\n ' + message,
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }
            if (action === 'modifyBooking') {
                isAnAction = true
                axios.put(burl + '/client/bookings/' + option.id, payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        let errorList = ''
                        if (err.response?.data.error) {
                            errorList = "\n" + errorRefiner(err.response.data.error).join(' \n,')
                        }
                        const message = errorList ? errorList : "veuillez verifier que vous avez bien remplis le formulaire."

                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande :\n ' + message,
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }
            if (action === 'deleteBooking') {
                isAnAction = true
                axios.post(burl + '/client/properties/' + option.name, payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande, veuillez verifier que vous avez bien remplis le formulaire',
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }
            //      INTERVENTIONS
            if (action === 'addIntervention') {
                isAnAction = true
                axios.post(burl + '/client/interventions/' + option.name, payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }
            if (action === 'ValidateInterventionConcierge') {
                isAnAction = true
                axios.post(burl + '/user/intervention/', payload, this.headerAuthFiles())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }
            if (action === 'modifyIntervention') {
                isAnAction = true
                axios.put(burl + '/client/interventions/' + option.id, payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande, veuillez verifier que vous avez bien remplis le formulaire',
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }
            if (action === 'modifyMultipleIntervention') {
                isAnAction = true
                axios.put(burl + '/client/interventionsMultiple/' + option.group, payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        let errorList = ''
                        if (err.response?.data.error) {
                            errorList = "\n" + errorRefiner(err.response.data.error).join(' \n,')
                        }
                        const message = errorList ? errorList : "veuillez verifier que vous avez bien remplis le formulaire."

                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande :\n ' + message,
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }

            //TEAM

            if (action === 'addTeam') {
                isAnAction = true
                axios.post(burl + '/user/', payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Equipe: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande, veuillez verifier que vous avez bien remplis le formulaire',
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }
            if (action === 'modifyTeam') {
                isAnAction = true
                axios.put(burl + '/user/' + option.email, payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Equipe: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande, veuillez verifier que vous avez bien remplis le formulaire',
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }

            //CONCIERGES

            if (action === 'addConcierge') {
                isAnAction = true
                axios.post(burl + '/concierge/', payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Concierge: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }
            if (action === 'modifyConcierge') {
                isAnAction = true
                axios.put(burl + '/concierge/' + option.email, payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Concierge: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande, veuillez verifier que vous avez bien remplis le formulaire',
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }
            if (action === 'deleteConcierge') {
                isAnAction = true
                axios.delete(burl + '/concierge/' + option.email, this.headerAuth())
                    .then((result) => {
                        this.addData({ Concierge: [result.data.Concierge], Client: result.data.Client })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            //PROVIDERS

            if (action === 'addProvider') {
                isAnAction = true
                axios.post(burl + '/provider/', payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Prestataires: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }
            if (action === 'modifyProvider') {
                isAnAction = true
                axios.put(burl + '/provider/', payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Prestataires: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            //PRODUCTS

            if (action === 'getProductsList') {
                isAnAction = true
                axios.get(burl + '/gestion/produits', this.headerAuthFiles())
                    .then((result) => {
                        const newData = this.state.data
                        newData.products = JSON.parse(result.data.products)
                        this.setState({ data: newData }, (err) => {
                            if (err) {
                                console.log(err, 'error')
                                this.setError({ message: err })
                                    .then(() => {
                                        return reject(err)
                                    })
                                    .catch((err2) => {
                                        return reject(err2)
                                    })

                            } else {
                                return resolve()
                            }
                        })
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            if (action === 'setProductsList') {
                isAnAction = true
                axios.post(burl + '/gestion/produits', payload, this.headerAuthFiles())
                    .then((result) => {
                        return resolve("i")
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            // Modify Home Sheets

            if (action === 'modifyHomeViewStructure') {
                isAnAction = true
                axios.post(burl + '/gestion/homeviewstructure', payload, this.headerAuth())
                    .then((result) => {
                        return resolve()
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            if (action === "sendInventory") {
                isAnAction = true
                let fd = new FormData()
                if (typeof payload !== 'object') {
                    return reject('Payload is not an object')
                } else {
                    payload = JSON.stringify(payload)
                    fd.append('inv', payload)
                    fd.append('when', "sortie")
                    fd.append('email', option)
                }
                axios.post(burl + '/gestion/sendInventory', fd, this.headerAuthFiles())
                    .then((result) => {
                        return resolve()
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            if (action === "addSector") {
                isAnAction = true
                axios.post(burl + "/gestion/sector/create", payload, this.headerAuthFilesTypeless())
                    .then((result) => {
                        return resolve(result.data)
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }
            if (action === "modifySector") {
                isAnAction = true
                axios.post(burl + "/gestion/sector/modify", payload, this.headerAuthFilesTypeless())
                    .then((result) => {
                        return resolve(result.data)
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            if (action === "getSectors") {
                isAnAction = true
                axios.get(burl + "/gestion/sector/get", this.headerAuth())
                    .then((result) => {
                        return resolve(result.data)
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            if (action === "deleteSector") {
                isAnAction = true
                const fd = new FormData()
                fd.append('id', payload.id)
                axios.post(burl + "/gestion/sector/delete/", fd, this.headerAuth())
                    .then((result) => {
                        return resolve()
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            //NOT AN ACTION
            if (!isAnAction) {
                return reject(`${action} is not a valid action`)
            }
        }
        )
    }

    componentWillMount() {
        let cookie = Cookies.get('mypacome-PM')
        if (cookie) {
            try {
                cookie = JSON.parse(cookie)
            } catch (e) {
                console.log(e)
                return null
            }
            this.setState({ loading: true })
            this.setUser(cookie.user, cookie.token)
                .then(() => {
                    this.apiReducer('fetchData')
                        .then(() => {
                            this.setState({ loading: false })
                            return null
                        })
                        .catch((e) => {
                            this.setState({ loading: true })
                            return null
                        })
                })
                .catch((err) => {
                    console.log(err)
                    return null
                })
        }
    }

    render() {
        const { user, token, data, error, loading } = this.state
            , { logout, isLogged, getBurl, apiReducer, setError, findInterventionPeople } = this

        return (
            <DataContext.Provider
                value={
                    {
                        user,
                        token,
                        data,
                        error,
                        loading,
                        apiReducer,
                        logout,
                        isLogged,
                        getBurl,
                        setError,
                        findInterventionPeople
                    }
                }>
                {this.props.children}
            </DataContext.Provider>
        )
    }
}

function errorRefiner(errorString) {
    const errorLongList = errorString.split(',')
    const errorRefinedList = []

    for (const errorLong of errorLongList) {
        const errorShort = errorLong.split(':')
        errorRefinedList.push(errorShort[errorShort.length - 1])
    }
    return errorRefinedList
}


export default DataContext