import React from 'react';
import { useEffect } from "react";

//COMPONENTS
import SendButton from "../Button/SendButton";
import ImagesFits from "../ImagesLists/ImagesFits";
import HomeView from "./Clients/Property/HomeView/HomeView";
import HomeViewNew from "./Clients/Property/HomeView/HomeViewNew"
import AddHomeView from "./Clients/Property/HomeView/AddHomeView";
import HomeViewPDF from './Clients/Property/HomeView/HomeViewPDF';
import HomeViewNewPDF from './Clients/Property/HomeView/HomeViewNewPDF';

//ICONS
import {
    BsFillTrashFill,
    BsPlus,
    BsClipboard,
    BsPencilSquare,
    BsCalendar,
    BsClipboardCheck,
    BsClipboardX
} from "react-icons/bs";

//CONTEXT
import UserContext from '../../../dataContext'
import ClientContactAdd from "./Clients/ClientContactAdd";
import ValidateIntervention from "./Intervention/ValidateIntervention";
import ModifyHomeView from "./Clients/Property/HomeView/ModifyHomeView";
import ModifyHomeViewNew from "./Clients/Property/HomeView/ModifyHomeViewNew";

//FUNCTIONS
import {
    toInputDate,
    mergeDeep,
    DescriptionConditional,
    minutesToHours,
    getFormattedDate,
    changeTaxes,
    toTTC,
    formatDate,
} from "../../../Utils/functions";

import BookingInventory from './OverlayElements/BookingInventory/BookingInventory';
import ClientInvoices from './Overlays/Billing/ClientInvoices';

import "../../../assets/scss/Overlay/general.scss"
import ProvidersModify from './Providers/ProvidersModify';
import ProvidersAdd from './Providers/ProvidersAdd';


export default function Overlay(props) {
    const context = React.useContext(UserContext)
    const burl = context.getBurl()
    const [obj, setObj] = React.useState("")
        , [option, setOption] = React.useState("")
        , [element, setElement] = React.useState({ list: [] })
        , [element2, setElement2] = React.useState({ list: [] })
        , [element3, setElement3] = React.useState({ list: [] })
        , [loading, setLoading] = React.useState(false)
        , [, r] = React.useState({})
        , [temp,] = React.useState([])
        , [, setIntervenants] = React.useState(element.list)
        , [currentIntervenants, setCurrentIntervenants] = React.useState(element.list)
    // , [productSearch, setProductSearch] = useState("")
    // , [productActive, setProductActive] = useState(false)
    // , [selectedProduct, setSelectedProduct] = useState("")

    const refresh = React.useCallback(() => {
        r({})
    }, [r])

    useEffect(() => {
        setElement({ list: temp });
    }, [temp]);

    useEffect(() => {
        setIntervenants(props.item.people);
        setCurrentIntervenants(props.item.people);
    }, [props.item.people]);

    // useEffect(() => {
    //     console.log("ELEMENT : ", element);
    // }, [element]);

    const closeProcess = function () {
        setElement({ list: [] })
        setElement2({ list: [] })
        setElement3({ list: [] })
        setObj("")
        props.close()
        refresh()
    }

    const close = function (e) {
        if (e.target === e.currentTarget) {
            context.setError({
                level: 'warning',
                message: 'Êtes vous sûrs de vouloir quitter ? \nToutes vos modifications non envoyées seront perdues',
                btn1Text: 'Rester',
                btn2Text: 'Quitter',
                btn2Action: () => {
                    closeProcess()
                }
            })
        }
    }

    const closeInstant = function (e) {
        if (e.target === e.currentTarget) {
            closeProcess()
        }
    }

    if (!props.item.platform && !props.item.choices) {
        return null
    }

    if (props.item.choices === "-") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Ajouter un élément</h2>
                                <p className={"form-text light"}>Faites un choix pour continuer</p>
                            </div>
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            let value = ""
                            for (const i of e.target) {
                                if (i.checked) value = i.value
                            }
                            props.edit({ choices: value })
                        }}>
                            <div className="mb-3 list-unstyled components">
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="Blocage"
                                        value="Booking"
                                    />
                                    <label className="form-check-label" htmlFor="Blocage"> Blocage </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="Intervention"
                                        value="Intervention"
                                    />
                                    <label className="form-check-label" htmlFor="Intervention"> Intervention </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="Client"
                                        value="Client"
                                    />
                                    <label className="form-check-label" htmlFor="Client"> Client </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="Subscription"
                                        value="Subscription"
                                    />
                                    <label className="form-check-label" htmlFor="Subscription"> Abonnement </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="Property"
                                        value="Property"
                                    />
                                    <label className="form-check-label" htmlFor="Property"> Propriété </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="Concierge"
                                        value="Concierge"
                                    />
                                    <label className="form-check-label" htmlFor="Concierge"> Concierge </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="Providers-new"
                                        value="Providers-new"
                                    />
                                    <label className="form-check-label" htmlFor="Providers-new"> Prestataire </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="Equipe"
                                        value="Equipe"
                                    />
                                    <label className="form-check-label" htmlFor="Equipe"> Equipe interne </label>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-12">
                                    <button type={"submit"} className={"btn btn-light w-100"}>Continuer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    //CONCIERGES

    if (props.item.choices === "Concierge") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={close} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Ajouter un concierge</h2>
                                <p className={"form-text light"}>Ajouters les informations necessaires</p>
                            </div>
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            let object = {}
                            for (const input of e.target) {
                                if (input.value) {
                                    const keys = input.name.split(".").reverse()
                                    let entry = { [keys[0]]: input.value }
                                    keys.shift()
                                    for (const key of keys) {
                                        entry = { [key]: entry }
                                    }
                                    object = mergeDeep(object, entry)
                                }
                            }
                            setLoading(true)
                            context.apiReducer('addConcierge', object)
                                .then(() => {
                                    setLoading(false)
                                    props.edit({ choices: "ConciergeSuccess" })
                                })
                                .catch(() => {
                                    setLoading(false)
                                })
                        }}>
                            <div className={"row"}>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="lastName" className="form-label">Nom</label>
                                    <input type="text" className="form-control" id="lastName" name="lastName"
                                        aria-describedby="start" required />
                                </div>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="name" className="form-label">Prénom</label>
                                    <input type="text" className="form-control" id="name" name="name"
                                        aria-describedby="end" required />
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="col-6 col-md-6">
                                    <div className={"mb-3"}>
                                        <label htmlFor="phone" className="form-label">Téléphone</label>
                                        <input type="phone" className="form-control" id="phone" name="phone"
                                            aria-describedby="end" required />
                                    </div>
                                </div>
                                <div className="col-6 col-md-6">
                                    <div className={"mb-3"}>
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input type="email" className="form-control" id="email" name="email"
                                            aria-describedby="end" required />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className={"row"}>
                                    <h3>Contrat</h3>
                                    <div className="col-6 col-md-6">
                                        <div className={"mb-3"}>
                                            <label htmlFor="contract.type" className="form-label">Contrat</label>
                                            <select className="form-select" id={"contract.type"} name={"contract.type"}
                                                defaultValue={""} required>
                                                <option value={""} selected>Type de contrat</option>
                                                <option value={"CDI"}>CDI</option>
                                                <option value={"CDD"}>CDD</option>
                                                <option value={"AUTO"}>Auto entrepreneur</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6">
                                        <div className={"mb-3"}>
                                            <label htmlFor="contract.companyID" className="form-label">Siret <small>(Nécessaire
                                                si auto)</small></label>
                                            <input type="text" className="form-control"
                                                id="contract.companyID" name="contract.companyID"
                                                aria-describedby="end" />
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6">
                                        <div className={"mb-3"}>
                                            <label htmlFor="contract.companyID" className="form-label">Prix
                                                horaire</label>
                                            <input type="number" className="form-control"
                                                min={0}
                                                id="contract.price" name="contract.price"
                                                aria-describedby="end" step="0.1" lang="fr" required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"mb-3"}>
                                    <h3>Adresse</h3>
                                    <div className="mb-3">
                                        <label htmlFor="address.zip" className="form-label">Code
                                            postal</label>
                                        <input type="text" className="form-control"
                                            id="address.zip" name="address.zip"
                                            aria-describedby="end" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="address.city"
                                            className="form-label">Ville</label>
                                        <input type="text" className="form-control"
                                            id="address.city" name="address.city"
                                            aria-describedby="end" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="address.road" className="form-label">Rue</label>
                                        <input type="text" className="form-control"
                                            id="address.road" name="address.road"
                                            aria-describedby="end" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="address.number"
                                            className="form-label">Numéro</label>
                                        <input type="text" className="form-control"
                                            id="address.number" name="address.number"
                                            aria-describedby="end" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="address.other"
                                            className="form-label">Autres informations</label>
                                        <input type="text" className="form-control"
                                            id="address.other" name="address.other"
                                            aria-describedby="end" />
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-12">
                                    <SendButton type={"submit"} loading={loading}>Envoyer</SendButton>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "ConciergeModify") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={close} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col-6"}>
                                <h2 className={""}>{props.item.lastName} - {props.item.name}</h2>
                                <p className={"form-text light"}>Modifiez les informations necessaires</p>
                            </div>
                            <div className={"col-6 d-flex justify-content-end align-items-start"}>
                                <button className={"btn btn-danger mt-2"} onClick={() => {
                                    context.setError({
                                        level: 'danger',
                                        message: `Êtes vous sur de vouloir supprimer le concierge : ${props.item.lastName} ${props.item.name}?`,
                                        btn1Text: 'Revenir',
                                        btn2Text: 'Supprimer',
                                        btn2Action: () => {
                                            context.apiReducer('deleteConcierge', { archive: true }, { email: props.item.email })
                                                .then(() => {
                                                    const object = props.item
                                                    object.choices = "ConciergeDeleteSuccess"
                                                    props.edit(object)
                                                    refresh()
                                                })
                                                .catch(err => {
                                                    console.log(err)
                                                })
                                        }
                                    })
                                }}>
                                    <BsFillTrashFill /> Archiver le concierge
                                </button>
                            </div>
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            let object = {}
                            for (const input of e.target) {
                                if (input.value) {
                                    const keys = input.name.split(".").reverse()
                                    let entry = { [keys[0]]: input.value }
                                    keys.shift()
                                    for (const key of keys) {
                                        entry = { [key]: entry }
                                    }
                                    object = mergeDeep(object, entry)
                                }
                            }

                            setLoading(true)
                            context.apiReducer('modifyConcierge', object, { email: props.item.email })
                                .then(() => {
                                    setLoading(false)
                                    props.edit({ choices: "ConciergeModifySuccess" })
                                })
                                .catch(() => {
                                    setLoading(false)
                                })
                        }}>
                            <div className={"row"}>
                                <div className="col-6 col-md-6">
                                    <div className={"mb-3"}>
                                        <label htmlFor="phone" className="form-label">Téléphone</label>
                                        <input type="phone" className="form-control"
                                            id="phone" name="phone"
                                            defaultValue={props.item.phone}
                                            aria-describedby="end" required />
                                    </div>
                                </div>
                                <div className="col-6 col-md-6">
                                    <div className={"mb-3"}>
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input type="email" className="form-control"
                                            id="email" name="email"
                                            defaultValue={props.item.email}
                                            aria-describedby="end" required />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className={"row"}>
                                    <h3>Contrat</h3>
                                    <div className="col-6 col-md-6">
                                        <div className={"mb-3"}>
                                            <label htmlFor="contract.type" className="form-label">Contrat</label>
                                            <select className="form-select"
                                                id={"contract.type"} name={"contract.type"}
                                                defaultValue={props.item.contract.type} required>
                                                <option value={""} selected>Type de contrat</option>
                                                <option value={"CDI"}>CDI</option>
                                                <option value={"CDD"}>CDD</option>
                                                <option value={"AUTO"}>Auto entrepreneur</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6">
                                        <div className={"mb-3"}>
                                            <label htmlFor="contract.companyID" className="form-label">Siret <small>(Nécessaire
                                                si auto)</small></label>
                                            <input type="text" className="form-control"
                                                id="contract.companyID" name="contract.companyID"
                                                defaultValue={props.item.contract.companyID}
                                                aria-describedby="end" />
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6">
                                        <div className={"mb-3"}>
                                            <label htmlFor="contract.companyID" className="form-label">Prix
                                                horaire</label>
                                            <input type="number" className="form-control"
                                                min={0}
                                                id="contract.price" name="contract.price"
                                                defaultValue={props.item.contract.price}
                                                aria-describedby="end" step="0.1" required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"mb-3"}>
                                    <h3>Adresse</h3>
                                    <div className="mb-3">
                                        <label htmlFor="address.zip" className="form-label">Code
                                            postal</label>
                                        <input type="text" className="form-control"
                                            id="address.zip" name="address.zip"
                                            defaultValue={props.item.address.zip}
                                            aria-describedby="end" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="address.city"
                                            className="form-label">Ville</label>
                                        <input type="text" className="form-control"
                                            id="address.city" name="address.city"
                                            defaultValue={props.item.address.city}
                                            aria-describedby="end" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="address.road" className="form-label">Rue</label>
                                        <input type="text" className="form-control"
                                            id="address.road" name="address.road"
                                            defaultValue={props.item.address.road}
                                            aria-describedby="end" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="address.number"
                                            className="form-label">Numéro</label>
                                        <input type="text" className="form-control"
                                            id="address.number" name="address.number"
                                            defaultValue={props.item.address.number}
                                            aria-describedby="end" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="address.other"
                                            className="form-label">Autres informations</label>
                                        <input type="text" className="form-control"
                                            id="address.other" name="address.other"
                                            defaultValue={props.item.address.other}
                                            aria-describedby="end" />
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-12">
                                    <SendButton type={"submit"} loading={loading}>Envoyer</SendButton>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "ConciergeSuccess") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Votre concierge a bien été ajouté</h2>
                                <p className={"form-text light"}>Voulez vous configurer d'autres éléments pour ce
                                    concierge
                                    ? </p>
                            </div>
                        </div>

                        <form onSubmit={(e) => {
                            e.preventDefault()
                            let value = ""
                            for (const i of e.target) {
                                if (i.checked) value = i.value
                            }
                            props.edit({ choices: value })
                        }}>
                            <div className="mb-3 list-unstyled components">
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="Intervention"
                                        value="Intervention"
                                    />
                                    <label className="form-check-label" htmlFor="Intervention"> Intervention </label>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-12">
                                    <button type={"submit"} className={"btn btn-light w-100"}>Continuer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "ConciergeModifySuccess") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Votre concierge a bien été modifié</h2>
                                <p className={"form-text light"}>Voulez vous configurer d'autres éléments pour ce
                                    concierge
                                    ? </p>
                            </div>
                        </div>
                        <p>

                        </p>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            let value = ""
                            for (const i of e.target) {
                                if (i.checked) value = i.value
                            }
                            props.edit({ choices: value })
                        }}>
                            <div className="mb-3 list-unstyled components">
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="Intervention"
                                        value="Intervention"
                                    />
                                    <label className="form-check-label" htmlFor="Intervention"> Intervention </label>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-12">
                                    <button type={"submit"} className={"btn btn-light w-100"}>Continuer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "ConciergeDeleteSuccess") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>{props.item.lastName} - {props.item.name} a bien été supprimé</h2>
                                <p className={"form-text light"}>Le concierge à été retiré de toutes les interventions
                                    sur les quelques il était affilié après la date d'aujourd'hui.</p>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="mb-3 col-12">
                                <button type={"button"} onClick={closeInstant} className={"btn btn-light w-100"}>Quitter
                                    le menu contextuel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    //CLIENTS

    if (props.item.choices === "ClientChoices") {
        const loaderInside = function () {
            setElement({ list: [] })
            const durationToMins = (string) => {
                const split = string.split(":")

                return parseInt(split[1]) + parseInt(split[0]) * 60
            }
            const checkOrCreate = (date, el, listTitle) => {
                const selector = listTitle ? listTitle : "list"
                const list = element[selector] === undefined ? [] : element[selector]

                //check if element exist else create it
                let yearSelector = list.findIndex((item) => item.year === date.getFullYear())

                if (yearSelector === -1) {
                    yearSelector = list.push({
                        year: date.getFullYear(),
                        list: [],
                        plannified: { count: 0, totalDuration: 0 },
                        validated: { count: 0, totalDuration: 0 },
                        passed: { count: 0, totalDuration: 0 }
                    }) - 1
                }
                let monthSelector = list[yearSelector].list.findIndex((item) => item.month === date.getMonth())
                if (monthSelector === -1) {
                    monthSelector = list[yearSelector].list.push({
                        month: date.getMonth(),
                        list: [],
                        plannified: { count: 0, totalDuration: 0 },
                        validated: { count: 0, totalDuration: 0 }
                    }) - 1
                }

                //push the element inside the array + put the total duration too and other informations
                const duration = durationToMins(el.date.duration)

                //  Plannified interventions

                //      monthly
                list[yearSelector].list[monthSelector].plannified.totalDuration += duration
                list[yearSelector].list[monthSelector].plannified.count += 1

                //      yearly
                list[yearSelector].plannified.totalDuration += duration
                list[yearSelector].plannified.count += 1

                //  Validated interventions
                if (!el.people.some((item) => !!item.finishDate)) {
                    //      monhtly
                    list[yearSelector].list[monthSelector].validated.totalDuration += duration
                    list[yearSelector].list[monthSelector].validated.count += 1

                    //      yearly
                    list[yearSelector].validated.totalDuration += duration
                    list[yearSelector].validated.count += 1
                }

                //  Passed interventions
                if (date < new Date()) {

                    //      yearly
                    list[yearSelector].passed.totalDuration += duration
                    list[yearSelector].passed.count += 1
                }

                list[yearSelector].list[monthSelector].list.push(el)


                const object = element
                object[selector] = list
                setElement(object)
            }

            const currentYear = new Date().getFullYear()

            // Create month lists and add interventions
            const contractStart = new Date(props.item.subscription?.start)
            const contractEnd = new Date(contractStart.getFullYear() + 1, contractStart.getMonth(), contractStart.getDate())

            for (const property of props.item.properties) {
                for (const intervention of property.interventions) {
                    if (
                        intervention.type === "R"
                        && !intervention.cancelled
                    ) {
                        const start = new Date(intervention.date.dateStart)
                        checkOrCreate(start, intervention, "list")
                        if (start >= contractStart
                            && start < contractEnd) {
                            checkOrCreate(start, intervention, "contractCurrent")
                        }
                    }
                }
            }

            let item = element
            if (item.list.length === 0) {
                item = {
                    list: [{
                        list: [],
                        passed: { count: 0, totalDuration: 0 },
                        plannified: { count: 0, totalDuration: 0 },
                        validated: { count: 0, totalDuration: 0 },
                        year: 2022
                    }],
                    contractCurrent: [{
                        list: [],
                        passed: { count: 0, totalDuration: 0 },
                        plannified: { count: 0, totalDuration: 0 },
                        validated: { count: 0, totalDuration: 0 },
                        year: 2022
                    }]
                }
            }
            if (!item.list.some((el) => el.year === currentYear)) {
                item.list.push({
                    list: [],
                    passed: { count: 0, totalDuration: 0 },
                    plannified: { count: 0, totalDuration: 0 },
                    validated: { count: 0, totalDuration: 0 },
                    year: 2022
                })
                item.contractCurrent = [{
                    list: [],
                    passed: { count: 0, totalDuration: 0 },
                    plannified: { count: 0, totalDuration: 0 },
                    validated: { count: 0, totalDuration: 0 },
                    year: 2022
                }]
            }

            // Create contract variables
            const start = new Date(props.item.subscription?.start)
            const end = new Date(start)
            end.setFullYear(start.getFullYear() + 1)


            // Creation of the current contract list of element
            const current = {}

            for (const index in item.contractCurrent) {
                for (const key in item.contractCurrent[index]) {
                    if (current[key] === undefined) {
                        current[key] = item.contractCurrent[index][key]
                    } else if (typeof item.contractCurrent[index][key] === "object") {
                        for (const subKey in item.contractCurrent[index][key]) {
                            if (current[key][subKey] === undefined) {
                                current[key][subKey] = item.contractCurrent[index][key][subKey]
                            } else if (Array.isArray(item.contractCurrent[index][key][subKey])) {
                                current[key][subKey] = current[key][subKey].concat(item.contractCurrent[index][key][subKey])
                            } else {
                                current[key][subKey] += item.contractCurrent[index][key][subKey]
                            }
                        }
                    } else if (Array.isArray(item.contractCurrent[key])) {
                        current[key] = current[key].concat(item.contractCurrent[index][key])

                    } else {
                        current[key] += item.contractCurrent[index][key]

                    }
                }
            }

            delete current.list
            delete current.year

            // console.log(current, item)
            item.current = current

            const interventionSplit = props.item.subscription.intervention.split(":")
            const minutes = (parseInt(interventionSplit[0]) * 60) + parseInt(interventionSplit[1])

            // console.log(item.current)
            const factor = item.current?.plannified?.totalDuration < minutes * 12 ? -1 : (item.current?.plannified?.totalDuration === minutes ? 0 : 1)

            item.subscription = {
                isSubscribed: !props.item.subscription,
                contractStart: start,
                contractEnd: end,
                hours: minutes * 12,
                factor: factor
            }

            setElement(item)
        }

        if (element.list?.length === 0
            && props.item.properties.some((property) => property.interventions.length > 0)
            && !!props.item.subscription
            && !props.item?.subscription?.aborted?.isAborted) {
            loaderInside()
        }
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col-6"}>
                                <h2 className={""}>{props.item.lastName} {props.item.name}</h2>
                                <p className={"form-text light"}>Téléphone : {props.item.phone}</p>
                                <p className={"form-text light"}>Email : {props.item.email}</p>
                                <p className={"form-text light"}>Adresse de facturation
                                    : {props.item.invoiceAddress.zip} {props.item.invoiceAddress.city} {props.item.invoiceAddress.number} {props.item.invoiceAddress.road}</p>
                                {props.item.professional ?
                                    <p className={"form-text light"}>Professionel : {props.item.companyID}</p> : null}
                            </div>
                            <div className={"col-6 d-flex justify-content-end align-items-start"}>
                                <button className={"btn btn-danger mt-2"} onClick={() => {
                                    context.setError({
                                        level: 'danger',
                                        message: 'Êtes vous sur de vouloir supprimer ce client ?',
                                        btn1Text: 'Revenir',
                                        btn2Text: 'Supprimer',
                                        btn2Action: () => {
                                            context.apiReducer('modifyClient', { archive: true }, { email: props.item.email })
                                                .then(() => {
                                                    props.edit({ choices: "ClientDelete" })
                                                })
                                        }
                                    })
                                }}>
                                    <BsFillTrashFill /> Archiver le client
                                </button>
                            </div>
                        </div>
                        {
                            !!props.item.subscription ?
                                <div hidden={!props.item.subscription}>
                                    <div className={"row g-0"}>
                                        <h4>Contrat actuel :
                                            Du {getFormattedDate(new Date(element?.subscription?.contractStart))} au {getFormattedDate(new Date(element?.subscription?.contractEnd))} </h4>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Heures Contractuelles</th>
                                                    <th>Heures Plannifiés</th>
                                                    <th>Heures Passées</th>
                                                    <th>Heures Validées</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{minutesToHours(element?.subscription?.hours)}</td>
                                                    <td>{minutesToHours(element?.current?.plannified?.totalDuration)}</td>
                                                    <td>{minutesToHours(element?.current?.passed?.totalDuration)}</td>
                                                    <td>{minutesToHours(element?.current?.validated?.totalDuration)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={"d-flex gauge-wrap"}>
                                        <div className={`arrow rotate${element?.subscription?.factor}`} />
                                        <div className={"gauge"}>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                        {element?.subscription?.factor > 1 ?
                                            // Too much hours
                                            <p className={"warning"}> Trop plein
                                                de {minutesToHours(element?.current?.plannified.totalDuration - element?.subscription.hours)} heures
                                                plannifiées par rapport au contrat signé </p>
                                            : (element?.subscription?.factor < 0 ?
                                                //Not enough hours
                                                <p className={"danger"}>Il
                                                    manque {minutesToHours(element?.subscription.hours - element?.current?.plannified.totalDuration)} heures
                                                    par rapport au contrat signé</p>
                                                :
                                                //Everything's okay
                                                <p className={"success"}>Toutes les heures prévues sont
                                                    plannifiées</p>
                                            )}
                                    </div>
                                </div>
                                :
                                null
                        }

                        <h4 className={"my-3"}
                            hidden={!props.item.contacts || props.item.contacts?.length === 0}>Contactes
                            secondaires</h4>
                        <div className={"additionalContacts"}
                            hidden={!props.item.contacts || props.item.contacts?.length === 0}>
                            {
                                props.item.contacts?.map((contact) => {
                                    return (
                                        <div className={"contactCards"}>
                                            <p>Nom : {contact.title}</p>
                                            <p>Téléphone : {contact.phone}</p>
                                            <p>Email : {contact.email}</p>
                                            <p> description : {contact.description}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            let value = ""
                            for (const i of e.target) {
                                if (i.checked) value = i.value
                            }
                            let object = props.item
                            if (value === "PropertyModify") object = option
                            object.choices = value
                            props.edit(object)
                        }}>
                            <div className="mb-3 list-unstyled components">
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="ClientModify"
                                        value="ClientModify"
                                    />
                                    <label className="form-check-label" htmlFor="ClientModify"> modifier le
                                        client </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="ClientInvoices"
                                        value="ClientInvoices"
                                    />
                                    <label className="form-check-label" htmlFor="ClientInvoices"> Facturation
                                        client </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="ClientContactAdd"
                                        value="ClientContactAdd"
                                    />
                                    <label className="form-check-label" htmlFor="ClientContactAdd"> Éditer les
                                        contacts</label>
                                </div>
                                {
                                    props.item.subscription ?
                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="radio"
                                                name="choices"
                                                id="SubscriptionModify"
                                                value="SubscriptionModify"
                                            />
                                            <label className="form-check-label"
                                                htmlFor="SubscriptionModify"> Modifier le contrat (abonnement et
                                                gestion locative) </label>
                                        </div>
                                        :
                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="radio"
                                                name="choices"
                                                id="Subscription"
                                                value="Subscription"
                                            />
                                            <label className="form-check-label"
                                                htmlFor="Subscription"> Créer un contrat (abonnement et gestion
                                                locative) </label>
                                        </div>
                                }

                                {
                                    props.item.properties.map((property) => {
                                        return (
                                            <>
                                                <div className="form-check">
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="choices"
                                                        id="PropertyModify"
                                                        value="PropertyModify"
                                                        onClick={() => {
                                                            setOption(property)
                                                        }}
                                                    />
                                                    <label className="form-check-label"
                                                        htmlFor="PropertyModify">Modification de la maison
                                                        : {property.name} - <small>{property.address.city}</small>
                                                    </label>
                                                </div>
                                                <div className="form-check" hidden={property.homeView?.length === 0}>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="choices"
                                                        id="PropertyHomeView"
                                                        value="PropertyHomeView"
                                                        onClick={() => {
                                                            setOption(property)
                                                        }}
                                                    />
                                                    <label className="form-check-label"
                                                        htmlFor="PropertyHomeView">Fiche Home
                                                        : {property.name} - <small>{property.address.city}</small>
                                                    </label>
                                                </div>
                                                <div className="form-check" hidden={property.homeViewNew?.length === 0}>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="choices"
                                                        id="PropertyHomeViewNew"
                                                        value="PropertyHomeViewNew"
                                                        onClick={() => {
                                                            setOption(property)
                                                        }}
                                                    />
                                                    <label className="form-check-label"
                                                        htmlFor="PropertyHomeViewNew">Fiche Home V2
                                                        : {property.name} - <small>{property.address.city}</small>
                                                    </label>
                                                </div>
                                                <div className="form-check"
                                                    hidden={property.homeViewNew?.length >= 1}>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="choices"
                                                        id="PropertyHomeViewAdd"
                                                        value="PropertyHomeViewAdd"
                                                        onClick={() => {
                                                            setOption(property)
                                                        }}
                                                    />
                                                    <label className="AddHomeView"
                                                        htmlFor="PropertyHomeViewAdd">Ajouter une fiche Home V2
                                                        : {property.name} - <small>{property.address.city}</small>
                                                    </label>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                                {/* <div className="form-check">
                                    <input className="form-check-input"
                                           type="radio"
                                           name="choices"
                                           id="ClientBillingAdd"
                                           value="ClientBillingAdd"
                                    />
                                    <label className="form-check-label" htmlFor="ClientBillingAdd"> Créer une facture</label>
                                </div> */}
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-12">
                                    <button type={"submit"} className={"btn btn-light w-100"}>Continuer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "Client") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={close} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Ajouter un Client</h2>
                                <p className={"form-text light"}>Ajouters les informations necessaires</p>
                            </div>
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            let object = {}
                            for (const input of e.target) {
                                if (input.value) {
                                    const keys = input.name.split(".").reverse()
                                    let entry = { [keys[0]]: input.value }
                                    keys.shift()
                                    for (const key of keys) {
                                        entry = { [key]: entry }
                                    }
                                    object = mergeDeep(object, entry)
                                }
                            }
                            setLoading(true)
                            context.apiReducer('addClient', object, { name: obj })
                                .then(() => {
                                    setLoading(false)
                                    props.edit({ choices: "ClientSuccess" })
                                })
                                .catch(() => {
                                    setLoading(false)
                                })
                        }}>
                            <div className={"row"}>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="lastName" className="form-label">Nom</label>
                                    <input type="text" className="form-control" id="lastName" name="lastName"
                                        aria-describedby="start" required />
                                </div>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="name" className="form-label">Prénom</label>
                                    <input type="text" className="form-control" id="name" name="name"
                                        aria-describedby="end" />
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="col-6 col-md-6">
                                    <div className={"mb-3"}>
                                        <label htmlFor="phone" className="form-label">Téléphone</label>
                                        <input type="phone" className="form-control" id="phone" name="phone"
                                            aria-describedby="end" required />
                                    </div>
                                </div>
                                <div className="col-6 col-md-6">
                                    <div className={"mb-3"}>
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input type="email" className="form-control" id="email" name="email"
                                            aria-describedby="end" required />
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="col-6 col-md-6">
                                    <div className={"mb-3"}>
                                        <label htmlFor="professional" className="form-label">Professionnel</label>
                                        <select className="form-select" id={"professional"} name={"professional"}
                                            defaultValue={""}>
                                            <option value={""} selected> Professionnel</option>
                                            <option value={true}>Oui</option>
                                            <option value={false}>Non</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6 col-md-6">
                                    <div className={"mb-3"}>
                                        <label htmlFor="companyID" className="form-label">Siret</label>
                                        <input type="text" className="form-control" id="companyID" name="companyID"
                                            aria-describedby="end" />
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="clientId" className="form-label">Numéro client (meg)</label>
                                    <input type="text" className="form-control" id="clientId" name="clientId"
                                        aria-describedby="end" placeholder="numéro MEG" />
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"mb-3"}>
                                    <h3>Adresse de facturation</h3>
                                    <div className="mb-3">
                                        <label htmlFor="invoiceAddress.zip" className="form-label">Code
                                            postal</label>
                                        <input type="text" className="form-control" id="invoiceAddress.zip"
                                            name="invoiceAddress.zip"
                                            aria-describedby="end" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="invoiceAddress.city"
                                            className="form-label">Ville</label>
                                        <input type="text" className="form-control" id="invoiceAddress.city"
                                            name="invoiceAddress.city"
                                            aria-describedby="end" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="invoiceAddress.road" className="form-label">Rue</label>
                                        <input type="text" className="form-control" id="invoiceAddress.road"
                                            name="invoiceAddress.road"
                                            aria-describedby="end" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="invoiceAddress.number"
                                            className="form-label">Numéro</label>
                                        <input type="text" className="form-control" id="invoiceAddress.number"
                                            name="invoiceAddress.number"
                                            aria-describedby="end" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="invoiceAddress.other"
                                            className="form-label">Autres informations</label>
                                        <input type="text" className="form-control" id="invoiceAddress.other"
                                            name="invoiceAddress.other"
                                            aria-describedby="end" />
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-12">
                                    <SendButton type={"submit"} loading={loading}>Envoyer</SendButton>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "ClientSuccess") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Votre client a bien été ajouté</h2>
                                <p className={"form-text light"}>Voulez vous configurer d'autres éléments pour ce client
                                    ? </p>
                            </div>
                        </div>
                        <p>

                        </p>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            let value = ""
                            for (const i of e.target) {
                                if (i.checked) value = i.value
                            }
                            props.edit({ choices: value })
                        }}>
                            <div className="mb-3 list-unstyled components">
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="Property"
                                        value="Property"
                                    />
                                    <label className="form-check-label" htmlFor="Property"> Propriété </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="Subscription"
                                        value="Subscription"
                                    />
                                    <label className="form-check-label" htmlFor="Prestataire"> Abonnement </label>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-12">
                                    <button type={"submit"} className={"btn btn-light w-100"}>Continuer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "ClientContactAdd") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={close} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>{props.item.lastName} - {props.item.name}</h2>
                                <p className={"form-text light"}>Modifier les contacts</p>
                            </div>
                        </div>
                        <ClientContactAdd submit={(e, list) => {
                            e.preventDefault()
                            setLoading(true)
                            // console.log(list)
                            context.apiReducer('modifyClient', { contacts: list }, { email: props.item.email })
                                .then(() => {
                                    setLoading(false)
                                    const item = props.item
                                    item.choices = "ClientChoices"
                                    props.edit(item)
                                })
                                .catch(() => {
                                    setLoading(true)
                                })
                        }}
                            loading={loading}
                            item={props.item} />
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "ClientModify") {
        // console.log(props.item)
        return (
            <div className={"overlay-modal"}>
                <div onClick={close} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>{props.item.lastName} - {props.item.name}</h2>
                                <p className={"form-text light"}>Modifier les informations necessaires</p>
                            </div>
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            let object = {}
                            if (loading) return false
                            for (const input of e.target) {
                                if (input.value) {
                                    const keys = input.name.split(".").reverse()
                                    let entry = { [keys[0]]: input.value }
                                    keys.shift()
                                    for (const key of keys) {
                                        entry = { [key]: entry }
                                    }
                                    object = mergeDeep(object, entry)
                                }
                            }
                            setLoading(true)


                            context.apiReducer('modifyClient', object, { email: props.item.email })
                                .then(() => {
                                    setLoading(false)
                                    const item = props.item
                                    item.choices = "ClientModifySuccess"
                                    props.edit(item)
                                })
                                .catch(() => {
                                    setLoading(true)
                                })
                        }}>
                            <div className={"row"}>
                                <div className="col-6 col-md-6">
                                    <div className={"mb-3"}>
                                        <label htmlFor="phone" className="form-label">Téléphone</label>
                                        <input type="phone" className="form-control"
                                            id="phone" name="phone"
                                            defaultValue={props.item.phone}
                                            aria-describedby="end" required />
                                    </div>
                                </div>
                                <div className="col-6 col-md-6">
                                    <div className={"mb-3"}>
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input type="email" className="form-control"
                                            id="email" name="email"
                                            defaultValue={props.item.email}
                                            aria-describedby="end" required />
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="col-6 col-md-6">
                                    <div className={"mb-3"}>
                                        <label htmlFor="clientId" className="form-label">Numéro client</label>
                                        <input type="text" className="form-control"
                                            id="clientId" name="clientId"
                                            defaultValue={props.item.clientId}
                                            aria-describedby="end" placeholder="Numéro Meg" required />

                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"mb-3"}>
                                    <h3>Adresse de facturation</h3>
                                    <div className="mb-3">
                                        <label htmlFor="invoiceAddress.zip" className="form-label">Code
                                            postal</label>
                                        <input type="text" className="form-control"
                                            id="invoiceAddress.zip" name="invoiceAddress.zip"
                                            defaultValue={props.item.invoiceAddress.zip}
                                            aria-describedby="end" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="invoiceAddress.city"
                                            className="form-label">Ville</label>
                                        <input type="text" className="form-control"
                                            id="invoiceAddress.city" name="invoiceAddress.city"
                                            defaultValue={props.item.invoiceAddress.city}
                                            aria-describedby="end" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="invoiceAddress.road" className="form-label">Rue</label>
                                        <input type="text" className="form-control"
                                            id="invoiceAddress.road" name="invoiceAddress.road"
                                            defaultValue={props.item.invoiceAddress.road}
                                            aria-describedby="end" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="invoiceAddress.number"
                                            className="form-label">Numéro</label>
                                        <input type="text" className="form-control"
                                            id="invoiceAddress.number" name="invoiceAddress.number"
                                            defaultValue={props.item.invoiceAddress.number}
                                            aria-describedby="end" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="invoiceAddress.other"
                                            className="form-label">Autres informations</label>
                                        <input type="text" className="form-control"
                                            id="invoiceAddress.other" name="invoiceAddress.other"
                                            defaultValue={props.item.invoiceAddress.other}
                                            aria-describedby="end" />
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-12">
                                    <SendButton type={"submit"} loading={loading}>Envoyer</SendButton>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "ClientModifySuccess") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Votre client a bien été modifié</h2>
                                <p className={"form-text light"}>Voulez vous configurer d'autres éléments pour ce client
                                    ? </p>
                            </div>
                        </div>
                        <p>

                        </p>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            let value = ""
                            for (const i of e.target) {
                                if (i.checked) value = i.value
                            }
                            props.edit({ choices: value })
                        }}>
                            <div className="mb-3 list-unstyled components">
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="Property"
                                        value="Property"
                                    />
                                    <label className="form-check-label" htmlFor="Property"> Propriété </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="Subscription"
                                        value="Subscription"
                                    />
                                    <label className="form-check-label" htmlFor="Prestataire"> Abonnement </label>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-12">
                                    <button type={"submit"} className={"btn btn-light w-100"}>Continuer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "ClientDelete") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Le client a bien été supprimé.</h2>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="mb-3 col-12">
                                <button type={"button"} onClick={() => {
                                    props.edit({ choices: "" })
                                }} className={"btn btn-light w-100"}>Fermer le menu contextuel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
    }

    //FACTURATION

    if (props.item.choices === "ClientInvoices") {
        let overlayProps = props
        const invoiceProps = {
            obj: obj,
            setObj: setObj,
            closeInstant: closeInstant,
            refresh: refresh,
            setLoading: setLoading,
            context: context,
            overlayProps: overlayProps
        };
        return (
            <ClientInvoices {...invoiceProps} />
        )

    }
    // if (props.item.choices === "ClientBillingAdd") {
    //     return (
    //         <div className={"overlay-modal"}>
    //             <div onClick={close} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
    //                 <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
    //                     <div className={"row mb-3 sticky-top border-bottom bg-success"}>
    //                         <div className={"col"}>
    //                             <h2 className={""}>Ajouter une facture pour {props.item.lastName} {props.item.name}</h2>
    //                             <p className={"form-text light"}>Ajouter une facture à la base de données</p>
    //                             <div className={"col-6"}>
    //                             {/* <h2 className={""}>{props.item.lastName} {props.item.name}</h2> */}
    //                             <p className={"form-text light"}    >N° client : ajouter num client</p>
    //                             <p className={"form-text light"}>Téléphone : {props.item.phone}</p>
    //                             <p className={"form-text light"}>Email : {props.item.email}</p>
    //                             <p className={"form-text light"}>Adresse de facturation
    //                                 : {props.item.invoiceAddress.zip} {props.item.invoiceAddress.city} {props.item.invoiceAddress.number} {props.item.invoiceAddress.road}</p>
    //                             {props.item.professional ?
    //                                 <p className={"form-text light"}>Professionel : {props.item.companyID}</p> : null}
    //                         </div>
    //                         </div>
    //                         <div className={"col-auto"}>
    //                             <button onClick={close} className={"btn btn-danger mt-3"}>Fermer</button>
    //                         </div>
    //                     </div>
    //                     <form onSubmit={(e) => {
    //                         e.preventDefault()

    //                         if (loading) {
    //                             return null
    //                         }
    //                         let object = {}
    //                         for (const input of e.target) {
    //                             if (input.value) {
    //                                 const keys = input.name.split(".").reverse()
    //                                 let entry = {[keys[0]]: input.value}
    //                                 keys.shift()
    //                                 for (const key of keys) {
    //                                     entry = {[key]: entry}
    //                                 }
    //                                 object = mergeDeep(object, entry)
    //                             }
    //                         }
    //                         delete object.add
    //                         setLoading(true)
    //                         // TODO: Add billing to database
    //                        /* context.apiReducer('modifySubscription', object, {email: props.item.email})
    //                             .then(() => {
    //                                 setLoading(false)
    //                                 props.edit({choices: "SubscriptionSuccess"})
    //                             })
    //                             .catch(() => {
    //                                 setLoading(false)
    //                             })*/
    //                     }}>
    //                         <h3>Général</h3>
    //             <div className={"row"}>
    //                             <div className="mb-3 col-6 col-md-6">
    //                                 <label htmlFor="start" className="form-label">Date de prélèvement</label>
    //                                 <input type="date" className="form-control"
    //                                        id="start" name="start"
    //                                        defaultValue={toInputDate(props.item.subscription.start)}
    //                                        aria-describedby="start" required/>
    //                                 <div id="start" className="form-text">Date de facturation.
    //                                 </div>
    //                             </div>
    //                             <div className="mb-3 col-6 col-md-6">
    //                                 <label htmlFor="price" className="form-label">Numéro de facture</label>
    //                                 <input type="number" className="form-control"
    //                                        min={10} pattern="^\d*(\.\d{0,2})?$" step=".01"
    //                                        id="price" name="price"
    //                                        defaultValue="Numéro précédent+1"
    //                                        aria-describedby="price" required/>
    //                             </div>
    //                             <div className="mb-3 col-6 col-md-6">
    //                                 <label htmlFor="start" className="form-label">Date de création</label>
    //                                 <input type="date" className="form-control"
    //                                        id="start" name="start"
    //                                        defaultValue={toInputDate(new Date())}
    //                                        aria-describedby="start" required/>
    //                                 <div id="start" className="form-text">Date de facturation.
    //                                 </div>
    //                             </div>
    //                 <div className={"column"}>
    //                     <hr/>
    //                     <button className={"btn btn-danger"} type={"button"}
    //                             >Supprimer <BsFillTrashFill/></button>
    //                     <div className="col-6 col-md-12">
    //                         <div className={"mb-3"}>
    //                             <label htmlFor={"contacts.title"} className="form-label">Nom</label>
    //                             <input type={"text"} className="form-control"
    //                                    id={"title"} name={"title"}
    //                                    defaultValue="test"
    //                                    onChange={(e) => {
    //                                     //    handleChange(index, e.target.name, e.target.value)
    //                                    }}
    //                                    aria-describedby="end" required/>
    //                         </div>
    //                     </div>
    //                     <div className="col-6 col-md-12">
    //                         <div className={"mb-3"}>
    //                             <label htmlFor={"phone"} className="form-label">Téléphone</label>
    //                             <input type="phone" className="form-control" name={"phone"}
    //                                    defaultValue="{contact.phone}"
    //                                    onChange={(e) => {
    //                                     //    handleChange(index, e.target.name, e.target.value)
    //                                    }}
    //                                    aria-describedby="end"/>
    //                         </div>
    //                     </div>

    //                     <div className="col-6 col-md-12">
    //                         <div className={"mb-3"}>
    //                             <label htmlFor={"email"} className="form-label">Libellé</label>
    //                             <input type="number" className="form-control"
    //                                    name={"label"}
    //                                    defaultValue="{contact.label?}"
    //                                    onChange={(e) => {
    //                                     //    handleChange(index, e.target.name, e.target.value)
    //                                    }}
    //                                    aria-describedby="end"/>
    //                         </div>
    //                     </div>
    //                     <div className="col-6 col-md-12">
    //                         <div className={"mb-3"}>
    //                             <label htmlFor={"description"} className="form-label">Description</label>
    //                             <textarea name={"description"} id="idtest" cols="80" rows="5"
    //                                       placeholder="Quelle prestation ?"
    //                                       defaultValue="Quelle prestation ?"
    //                                       onChange={(e) => {
    //                                         //   handleChange(index, e.target.name, e.target.value)
    //                                       }}
    //                                       className="form-control"
    //                                       aria-describedby="end"/>
    //                         </div>
    //                     </div>
    //                 </div>

    //             </div>

    //                         <div className={"row"}>
    //                         <h3>Coordonnées bancaires</h3>
    //                             <div className="mb-3 col-6 col-md-6">
    //                                 <label htmlFor="name">Nom</label>
    //                                 <input type="text" className="form-control" id="name" name="name"/>

    //                             </div>

    //                             <div className="mb-3 col-6 col-md-6">
    //                             <label htmlFor="name">IBAN</label>
    //                                 <input type="text" className="form-control" id="iban" name="iban"/>
    //                             </div>
    //                         </div>
    //                         <div className="mb-3 col-6 col-md-6">
    //                             <label htmlFor="name">BIC</label>
    //                                 <input type="text" className="form-control" id="bic" name="bic"/>
    //                             </div>
    //                         <div>
    //                             <h3>Détails de la TVA</h3>

    //                             <div className={"mb-3 row"}>
    //                                 <div className={"mb-3 col-6 col-md-6"}>
    //                                     <label htmlFor="renting.renting"
    //                                            className="form-label">Code</label>
    //                                     <select className="form-select" id="codeTVA" name="codeTVA"
    //                                             defaultValue="Normal" required>
    //                                         <option value="normal">Normal</option>
    //                                         <option value="no sé">Quoi d'autre ?</option>
    //                                     </select>
    //                                 </div>
    //                                 <div className="mb-3 col-6 col-md-6">
    //                                     <label htmlFor="percentageTVA"
    //                                            className="form-label">% du taux</label>
    //                                     <input type="number" min={10} max={100} className="form-control"
    //                                            id="percentageTVA" name="percentageTVA"
    //                                            step={".01"}
    //                                            defaultValue={20}
    //                                            aria-describedby="end"/>
    //                                 </div>
    //                             </div>
    //                         </div>

    //                         <div className={"row"}>
    //                             <div className="mb-3 col-12">
    //                                 <SendButton type={"submit"} loading={loading}>Envoyer</SendButton>
    //                             </div>
    //                         </div>
    //                     </form>
    //                 </div>
    //             </div>
    //         </div>
    //     )}

    //SUBSCRIPTION

    if (props.item.choices === "Subscription") {
        if (!obj && props.item?.email) {
            setObj(props.item.email)
            refresh()
        }

        return (
            <div className={"overlay-modal"}>
                <div onClick={close} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Ajouter un abonnement</h2>
                                <p className={"form-text light"}>Ajouters les informations necessaires</p>
                            </div>
                        </div>
                        <div className={"mb-3"}>
                            <label htmlFor="client" className="form-label">Client concerné</label>
                            <select className="form-select" name={"client"} id={"client"} required
                                onChange={(e) => {
                                    setObj(e.target.value)
                                }}>
                                <option value={""}>Sélectionnez un client</option>
                                {context.data.people.Client
                                    .sort((a, b) => a.lastName.localeCompare(b.lastName))
                                    .map((item) => {
                                        if (item.archive || item.subscription) return null
                                        if (item.email === obj) {
                                            return (
                                                <option selected
                                                    value={item.email}>{item.lastName + " " + item.name}</option>
                                            )
                                        }
                                        return (
                                            <option value={item.email}>{item.lastName + " " + item.name}</option>
                                        )
                                    })}
                            </select>
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault()

                            if (loading) {
                                return null
                            }
                            let object = {}
                            for (const input of e.target) {
                                if (input.value) {
                                    const keys = input.name.split(".").reverse()
                                    let entry = { [keys[0]]: input.value }
                                    keys.shift()
                                    for (const key of keys) {
                                        entry = { [key]: entry }
                                    }
                                    object = mergeDeep(object, entry)
                                }
                            }
                            delete object.add
                            setLoading(true)
                            context.apiReducer('addSubscription', object, { email: obj })
                                .then(() => {
                                    setLoading(false)
                                    props.edit({ choices: "SubscriptionSuccess" })
                                })
                                .catch(() => {
                                    setLoading(false)
                                })
                        }}>
                            <h3>Abonnement</h3>
                            <div className={"row"}>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="start" className="form-label">Date de début</label>
                                    <input type="date" className="form-control" id="start" name="start"
                                        aria-describedby="start" required />
                                    <div id="start" className="form-text">Date de début de contrat et de facturation.
                                    </div>
                                </div>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="price" className="form-label">Prix de l'abonnement hors taxe</label>
                                    <input type="number" className="form-control"
                                        min={10} pattern="^\d*(\.\d{0,2})?$" step=".01"
                                        id="price" name="price"
                                        aria-describedby="price" required />
                                </div>
                                <div className={"mb-3"}>
                                    <label htmlFor="engagement" className="form-label">Engagement</label>
                                    <select className="form-select" id={"engagement"} name={"engagement"}
                                        defaultValue={""} required>
                                        <option value={""} selected>Engagement ?</option>
                                        <option value={true}>Oui</option>
                                        <option value={false}>Non</option>
                                    </select>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="intervention" className="form-label">Temps d'intervention prévu
                                        (Mensuel)</label>
                                    <input type="time" className="form-control"
                                        id="intervention" name="intervention"
                                        defaultValue={"01:00"}
                                        aria-describedby="end" required />
                                    <div id="end" className="form-text">
                                    </div>
                                </div>

                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="management" className="form-label">Temps de gestion prévu
                                        (Mensuel)</label>
                                    <input type="time" className="form-control"
                                        id="management" name="management"
                                        defaultValue={"01:00"}
                                        aria-describedby="end" required />
                                </div>
                            </div>

                            <div>
                                <h3>Service de location</h3>

                                <div className={"mb-3 row"}>
                                    <div className={"mb-3 col-6 col-md-6"}>
                                        <label htmlFor="renting.renting"
                                            className="form-label">Gestion locative</label>
                                        <select className="form-select" id={"renting.renting"} name={"renting.renting"}
                                            defaultValue={""} required>
                                            <option value={true}>Oui</option>
                                            <option value={false}>Non</option>
                                        </select>
                                    </div>
                                    <div className="mb-3 col-6 col-md-6">
                                        <label htmlFor="renting.rate"
                                            className="form-label">% de commission HT</label>
                                        <input type="number" min={10} max={100} className="form-control"
                                            id="renting.rate" name="renting.rate"
                                            step={".01"}
                                            aria-describedby="end" />
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-12">
                                    <SendButton type={"submit"} loading={loading}>Envoyer</SendButton>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "SubscriptionModify") {

        return (
            <div className={"overlay-modal"}>
                <div onClick={close} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Modifier l'abonnement
                                    de {props.item.lastName} - {props.item.name}</h2>
                                <p className={"form-text light"}>Modifiez les informations necessaires</p>
                            </div>
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault()

                            if (loading) {
                                return null
                            }
                            let object = {}
                            for (const input of e.target) {
                                if (input.value) {
                                    const keys = input.name.split(".").reverse()
                                    let entry = { [keys[0]]: input.value }
                                    keys.shift()
                                    for (const key of keys) {
                                        entry = { [key]: entry }
                                    }
                                    object = mergeDeep(object, entry)
                                }
                            }
                            delete object.add
                            setLoading(true)
                            context.apiReducer('modifySubscription', object, { email: props.item.email })
                                .then(() => {
                                    setLoading(false)
                                    props.edit({ choices: "SubscriptionSuccess" })
                                })
                                .catch(() => {
                                    setLoading(false)
                                })
                        }}>
                            <h3>Abonnement</h3>
                            <div className={"row"}>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="start" className="form-label">Date de début</label>
                                    <input type="date" className="form-control"
                                        id="start" name="start"
                                        defaultValue={toInputDate(props.item.subscription.start)}
                                        aria-describedby="start" required />
                                    <div id="start" className="form-text">Date de début de contrat et de facturation.
                                    </div>
                                </div>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="price" className="form-label">Prix de l'abonnement hors taxe</label>
                                    <input type="number" className="form-control"
                                        min={10} pattern="^\d*(\.\d{0,2})?$" step=".01"
                                        id="price" name="price"
                                        defaultValue={props.item.subscription.price}
                                        aria-describedby="price" required />
                                </div>
                                <div className={"mb-3"}>
                                    <label htmlFor="engagement" className="form-label">Engagement</label>
                                    <select className="form-select" id={"engagement"} name={"engagement"}

                                        defaultValue={props.item.subscription.engagement}>
                                        <option selected>Engagement ?</option>
                                        <option value={true}>Oui</option>
                                        <option value={false}>Non</option>
                                    </select>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="intervention" className="form-label">Temps d'intervention prévu
                                        (Mensuel)</label>
                                    <input type="time" className="form-control"
                                        id="intervention" name="intervention"
                                        defaultValue={props.item.subscription.intervention}
                                        aria-describedby="end" required />
                                    <div id="end" className="form-text">
                                    </div>
                                </div>

                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="management" className="form-label">Temps de gestion prévu
                                        (Mensuel)</label>
                                    <input type="time" className="form-control"
                                        id="management" name="management"
                                        defaultValue={props.item.subscription.intervention}
                                        aria-describedby="end" required />
                                </div>
                            </div>

                            <div>
                                <h3>Service de location</h3>

                                <div className={"mb-3 row"}>
                                    <div className={"mb-3 col-6 col-md-6"}>
                                        <label htmlFor="renting.renting"
                                            className="form-label">Gestion locative</label>
                                        <select className="form-select" id={"renting.renting"} name={"renting.renting"}
                                            defaultValue={props.item.subscription.renting.renting} required>
                                            <option value={true}>Oui</option>
                                            <option value={false}>Non</option>
                                        </select>
                                    </div>
                                    <div className="mb-3 col-6 col-md-6">
                                        <label htmlFor="renting.rate"
                                            className="form-label">% de commission HT</label>
                                        <input type="number" min={10} max={100} className="form-control"
                                            id="renting.rate" name="renting.rate"
                                            step={".01"}
                                            defaultValue={props.item.subscription.renting.rate}
                                            aria-describedby="end" />
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-12">
                                    <SendButton type={"submit"} loading={loading}>Envoyer</SendButton>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "SubscriptionSuccess") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Votre abonnement a bien été ajouté</h2>
                                <p className={"form-text light"}>Voulez vous configurer d'autres éléments pour cet
                                    client ? </p>
                            </div>
                        </div>
                        <p>

                        </p>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            let value = ""
                            for (const i of e.target) {
                                if (i.checked) value = i.value
                            }
                            props.edit({ choices: value })
                        }}>
                            <div className="mb-3 list-unstyled components">
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="Intervention"
                                        value="Intervention"
                                    />
                                    <label className="form-check-label" htmlFor="Intervention"> Intervention </label>
                                </div>
                            </div>
                            <div className={"row mb-3"}>
                                <div className="mb-3 col-12">
                                    <button type={"submit"} className={"btn btn-light w-100"}>Continuer</button>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className="mb-3 col-12">
                                    <button type={"button"} className={"btn btn-light w-100"}
                                        onClick={closeInstant}>Quitter le menu contextuel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    //PROPERTIES

    if (props.item.choices === "Property") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={close} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Ajouter une propriété</h2>
                                <p className={"form-text light"}>Ajouters les informations necessaires</p>
                            </div>
                        </div>
                        <div className={"mb-3"}>
                            <select className="form-select" name={"client"} required defaultValue={""}
                                onChange={(e) => {
                                    setObj(e.target.value)
                                }}>
                                <option value={""} aria-disabled>Sélectionnez un client</option>
                                {context.data.people.Client.sort((a, b) => a.lastName.localeCompare(b.lastName)).map((item) => {
                                    return (
                                        <option value={item.email}>{item.lastName + " " + item.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            let object = {}
                            for (const input of e.target) {
                                if (input.value) {
                                    const keys = input.name.split(".").reverse()
                                    let entry = { [keys[0]]: input.value }
                                    keys.shift()
                                    for (const key of keys) {
                                        entry = { [key]: entry }
                                    }
                                    object = mergeDeep(object, entry)
                                }
                            }
                            object.calendar = element.list
                            setLoading(true)
                            context.apiReducer('addProperty', { email: obj, value: object })
                                .then(() => {
                                    setLoading(false)
                                    props.edit({ choices: "PropertySuccess" })
                                })
                                .catch(() => {
                                    setLoading(false)
                                })
                        }}>
                            <h3>Le bien</h3>
                            <div className={"row"}>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="name" className="form-label">Nom</label>
                                    <input type="text" className="form-control" id="name" name="name"
                                        aria-describedby="start" required />
                                </div>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="cleaningDuration" className="form-label">Durée moyenne du
                                        ménage</label>
                                    <input type="time" className="form-control" id="cleaningDuration"
                                        name="cleaningDuration" defaultValue={"01:00"}
                                        aria-describedby="start" required />
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"mb-3"}>
                                    <h3>Adresse du bien</h3>
                                    <div className="mb-3">
                                        <label htmlFor="address.zip" className="form-label">Code
                                            postal</label>
                                        <input type="text" className="form-control" id="address.zip"
                                            name="address.zip"
                                            aria-describedby="end" required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="address.city"
                                            className="form-label">Ville</label>
                                        <input type="text" className="form-control" id="address.city"
                                            name="address.city"
                                            aria-describedby="end" required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="address.road" className="form-label">Rue</label>
                                        <input type="text" className="form-control" id="address.road"
                                            name="address.road"
                                            aria-describedby="end" required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="address.number"
                                            className="form-label">Numéro</label>
                                        <input type="text" className="form-control" id="address.number"
                                            name="address.number"
                                            aria-describedby="end" required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="address.other"
                                            className="form-label">Autres informations</label>
                                        <input type="text" className="form-control" id="address.other"
                                            name="address.other"
                                            aria-describedby="end" />
                                    </div>
                                </div>
                            </div>
                            <h3>Géolocalisation du bien</h3>
                            <div className={"row"}>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="geo.long" className="form-label">Longitude</label>
                                    <input type="text" className="form-control" id="geo.long"
                                        name="geo.long"
                                        aria-describedby="end" required />
                                </div>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="geo.lat" className="form-label">Latitude</label>
                                    <input type="text" className="form-control" id="geo.lat"
                                        name="geo.lat"
                                        aria-describedby="end" required />
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"mb-3"}>
                                    <h3>Liens de calendriers</h3>
                                    <label className="form-label">Ajouter un lien</label>
                                    <div className="input-group mb-3">
                                        <select className="form-select"
                                            id="add.link.name"
                                            name="add.link.name"
                                            placeholder={"Nom du site"}
                                            defaultValue={""}
                                            aria-describedby="avoid">
                                            <option value={""}></option>
                                            <option value={"airbnb"}>Airbnb</option>
                                            <option value={"booking"}>Booking</option>
                                            <option value={"homeaway"}>Homeaway/Abritel</option>
                                            <option value={"gdf"}>Gites de France</option>
                                            <option value={"other"}>Autre</option>
                                        </select>
                                        <input type="text" className="form-control"
                                            id="add.link.link"
                                            name="add.link.link"
                                            placeholder={"lien"}
                                            defaultValue={""}
                                            aria-describedby="avoid" />
                                        <span className="input-group-text"
                                            onClick={() => {
                                                const tList = element.list
                                                    ,
                                                    name = document.querySelector('select[id="add.link.name"]').value
                                                    ,
                                                    link = document.querySelector('input[id="add.link.link"]').value

                                                if (!element.list.some((el) => el.name === document.querySelector('select[id="add.link.name"]').value) && link) {
                                                    tList.push({ name: name, link: link })
                                                    document.querySelector('select[id="add.link.name"]').value = ""
                                                    document.querySelector('input[id="add.link.link"]').value = ""
                                                    setElement({ list: tList })
                                                }
                                            }}><BsPlus /></span>
                                    </div>
                                    <div className="mb-3">
                                        <h5>Liste des services</h5>
                                        <ul className={"list-group"}>
                                            {
                                                element.list.map((item) => {
                                                    return <li
                                                        className={"list-group-item list-group-item-action d-flex justify-content-between"}>
                                                        {item.name} - {item.link}
                                                        <span className={""}>DELETE</span>
                                                    </li>

                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-12">
                                    <SendButton type={"submit"} loading={loading}
                                        className={"btn btn-light w-100"}>Envoyer</SendButton>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "PropertyModify") {
        if ((Object.keys(element).length > 1 || element.list.length === 0) && props.item.calendar?.length > 0) {
            const el = {}
            el.list = props.item.calendar
            setElement(el)
        }
        return (
            <div className={"overlay-modal"}>
                <div onClick={close} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Modifier {props.item.name}</h2>
                                <p className={"form-text light"}>Modifier les informations necessaires</p>
                            </div>
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            let object = {}
                            for (const input of e.target) {
                                if (input.value) {
                                    if (input.name === "guide") {
                                        console.log(input.files[0], "guide?")
                                        object.guide = input.files[0]
                                        continue
                                    }
                                    if (input.name === "pool") {
                                        // console.log(input.checked, "pool?")
                                        object.pool = input.checked
                                        continue
                                    }
                                    const keys = input.name.split(".").reverse()
                                    let entry = { [keys[0]]: input.value }
                                    keys.shift()
                                    for (const key of keys) {
                                        entry = { [key]: entry }
                                    }
                                    object = mergeDeep(object, entry)
                                }
                            }
                            if (element.list.length > 0) object.calendar = element.list
                            setLoading(true)
                            context.apiReducer('modifyProperty', object, { name: props.item.name })
                                .then(() => {
                                    setLoading(false)
                                    props.edit({ choices: "PropertyModifySuccess" })
                                })
                                .catch(() => {
                                    setLoading(false)
                                })
                        }}>
                            <h3>Le bien</h3>
                            <div className={"row"}>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="name" className="form-label">Nom</label>
                                    <input type="text" className="form-control"
                                        id="name" name="name"
                                        defaultValue={props.item.name}
                                        aria-describedby="start" required />
                                </div>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="cleaningDuration" className="form-label">Durée moyenne du
                                        ménage</label>
                                    <input type="time" className="form-control"
                                        id="cleaningDuration" name="cleaningDuration"
                                        defaultValue={props.item.cleaningDuration}
                                        aria-describedby="start" required />
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"mb-3"}>
                                    <h3>Adresse du bien</h3>
                                    <div className="mb-3">
                                        <label htmlFor="address.zip" className="form-label">Code
                                            postal</label>
                                        <input type="text" className="form-control"
                                            id="address.zip" name="address.zip"
                                            defaultValue={props.item.address.zip}
                                            aria-describedby="end" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="address.city"
                                            className="form-label">Ville</label>
                                        <input type="text" className="form-control"
                                            id="address.city" name="address.city"
                                            defaultValue={props.item.address.city}
                                            aria-describedby="end" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="address.road" className="form-label">Rue</label>
                                        <input type="text" className="form-control"
                                            id="address.road" name="address.road"
                                            defaultValue={props.item.address.road}
                                            aria-describedby="end" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="address.number"
                                            className="form-label">Numéro</label>
                                        <input type="text" className="form-control"
                                            id="address.number" name="address.number"
                                            defaultValue={props.item.address.number}
                                            aria-describedby="end" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="address.other"
                                            className="form-label">Autres informations</label>
                                        <input type="text" className="form-control"
                                            id="address.other" name="address.other"
                                            defaultValue={props.item.address.other}
                                            aria-describedby="end" />
                                    </div>
                                </div>
                            </div>

                            <h3>Piscine ?</h3>
                            <div className='row'>
                                <div className='mb-3 col-6 col-md-6'>
                                    <input type='checkbox' className='form-check-input' id='pool' name='pool'
                                        defaultChecked={props.item.pool} />
                                </div>
                            </div>
                            {console.log(props.item.pool)}

                            <h3>Guide du bien</h3>
                            {props.item.guide ? (<div className='row'>
                                <div className='mb-3 file-div'>
                                    {/* Remove everything before the last slash (in the case there is one) to get rid of the path and just have the file name */}
                                    <p>"{props.item.guide.split("/").pop()}"</p>
                                    <a href={context.getBurl() + "/" + props.item.guide} target="_blank" rel="noreferrer" className="noFile">Voir le guide</a>
                                    {/* Button to clear the guide with an alert()  prompt */}
                                    <button href="#" className='noFile' onClick={(e) => {
                                        e.preventDefault()
                                        if (window.confirm("Voulez-vous vraiment supprimer ce guide ?")) {
                                            const object = {}
                                            object.guide = ""
                                            context.apiReducer('modifyProperty', object, { name: props.item.name })
                                                .then(() => {
                                                    props.edit({ choices: "PropertyModifySuccess" })
                                                })
                                        }
                                    }}>Supprimer le guide</button>
                                </div>

                            </div>) : null}
                            <div className='row'>
                                <div className='mb-3 col-6 col-md-6'>
                                    <label htmlFor='guide' className='form-label'>Document de guide pour le bien</label>
                                    <input type='file' accept="application/pdf" className='form-control' id='guide' name='guide' />
                                </div>
                            </div>

                            <h3>Géolocalisation du bien</h3>
                            <div className={"row"}>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="geo.long" className="form-label">Longitude</label>
                                    <input type="text" className="form-control" id="geo.long"
                                        name="geo.long" defaultValue={props.item?.geo?.long}
                                        aria-describedby="end" required />
                                </div>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="geo.lat" className="form-label">Latitude</label>
                                    <input type="text" className="form-control" id="geo.lat"
                                        name="geo.lat" defaultValue={props.item?.geo?.lat}
                                        aria-describedby="end" required />
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"mb-3"}>
                                    <h3>Liens de calendriers</h3>

                                    <div>
                                        <div className={"d-inline-flex"}>
                                            <button className={"btn btn-light mx-1"} onClick={() => {
                                                let copyText = context.getBurl() + '/' + props.item.calendarLink

                                                /* Copy the text inside the text field */
                                                navigator.clipboard.writeText(copyText);
                                            }}><BsClipboard /></button>
                                            <p>Lien du calendrier <small>(sites de
                                                reservation)</small> :
                                            </p>
                                        </div>

                                        <p className={"overflow-hidden"}>
                                            <small>{context.getBurl() + '/' + props.item.calendarLink}</small></p>
                                    </div>


                                    <div>
                                        <div className={"d-inline-flex"}>
                                            <button className={"btn btn-light mx-1"} onClick={() => {
                                                let copyText = context.getBurl() + '/' + props.item.calendarLinkALL

                                                /* Copy the text inside the text field */
                                                navigator.clipboard.writeText(copyText);
                                            }}><BsClipboard /></button>
                                            <p className={"mb-auto"}>Lien du calendrier <small>(sites
                                                MYPacome)</small>:
                                            </p>
                                        </div>

                                        <p className={"overflow-hidden"}>
                                            <small>{context.getBurl() + '/' + props.item.calendarLinkALL}</small></p>
                                    </div>

                                    <label className="form-label">Ajouter un lien</label>
                                    <div className="input-group mb-3">
                                        <select className="form-select"
                                            id="add.link.name"
                                            name="add.link.name"
                                            placeholder={"Nom du site"}
                                            defaultValue={""}
                                            aria-describedby="avoid">
                                            <option value={""}></option>
                                            <option value={"airbnb"}>Airbnb</option>
                                            <option value={"booking"}>Booking</option>
                                            <option value={"homeaway"}>Homeaway/Abritel</option>
                                            <option value={"gdf"}>Gites de France</option>
                                            <option value={"other"}>Autre</option>
                                        </select>
                                        <input type="text" className="form-control"
                                            id="add.link.link"
                                            name="add.link.link"
                                            placeholder={"lien"}
                                            defaultValue={""}
                                            aria-describedby="avoid" />
                                        <span className="input-group-text"
                                            onClick={() => {
                                                const tList = element.list
                                                    ,
                                                    name = document.querySelector('select[id="add.link.name"]').value
                                                    ,
                                                    link = document.querySelector('input[id="add.link.link"]').value

                                                if (!element.list.some((el) => el.name === document.querySelector('select[id="add.link.name"]').value) && link) {
                                                    tList.push({ name: name, link: link })
                                                    document.querySelector('select[id="add.link.name"]').value = ""
                                                    document.querySelector('input[id="add.link.link"]').value = ""
                                                    setElement({ list: tList })
                                                }
                                            }}><BsPlus /></span>
                                    </div>
                                    <div className="mb-3">
                                        <h5>Liste des calendriers</h5>
                                        <ul className={"list-group"}>
                                            {
                                                element.list.map((item) => {
                                                    // console.log(item)
                                                    return <li
                                                        className={"list-group-item d-flex justify-content-between"}>
                                                        {item.name} - {item.link} {item.error ?
                                                            <p className={"danger"}>Problème sur ce lien</p> : null}
                                                        <button className={"btn btn-light"}
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                const object = element
                                                                object.list.splice(element.list.findIndex(el => item === el), 1)
                                                                setElement(object)
                                                                refresh()
                                                            }}>
                                                            <BsFillTrashFill /></button>
                                                    </li>

                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-12">
                                    <SendButton loading={loading} type={"submit"}
                                        className={"btn btn-light w-100"}>Envoyer</SendButton>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "PropertyHomeViewAdd") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={close} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2>Fiche Home de {props.item.name}</h2>
                            </div>
                        </div>
                        <AddHomeView send={(list) => {
                            const fd = new FormData()
                            for (const item of list) {
                                const images = []
                                for (let i = 0; i < item.images.length; i++) {
                                    images.push(item.images[i].name)
                                    fd.append('files', item.images[i]);
                                    item.images[i] = item.images[i].name
                                }
                            }
                            fd.append('homeViewNew', JSON.stringify(list))
                            fd.append('client', props.item.email)
                            fd.append('property', option.name)

                            context.apiReducer('addHomeViewNew', fd, {})
                                .then((response) => {
                                    props.edit({ choices: "PropertyHomeViewSuccess" })
                                })
                        }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "PropertyHomeViewModify") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={close} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2>Modifier la fiche Home de {props.item.name}</h2>
                            </div>
                        </div>
                        <ModifyHomeView
                            burl={burl}
                            homeView={option.homeView.map((item, index) => {
                                return ({
                                    title: item.title,
                                    images: [],
                                    text: item.text,
                                    defaultImages: item.images,
                                    deletable: index > 5
                                })
                            })}
                            send={(list) => {
                                const fd = new FormData()
                                for (const item of list) {
                                    const images = []
                                    for (let i = 0; i < item.images.length; i++) {
                                        images.push(item.images[i].name)
                                        fd.append('files', item.images[i]);
                                    }
                                    fd.append('homeView', JSON.stringify({
                                        title: item.title,
                                        text: item.text,
                                        images: images
                                    }))
                                }
                                fd.append('client', props.item.email)
                                fd.append('property', option.name)
                                context.apiReducer('addHomeView', fd, {})
                                    .then((response) => {
                                        props.edit({ choices: "PropertyHomeViewSuccess" })
                                    })
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "PropertyHomeViewAdd") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={close} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2>Fiche Home de {props.item.name}</h2>
                            </div>
                        </div>
                        <AddHomeView send={(list) => {
                            const fd = new FormData()
                            for (const item of list) {
                                const images = []
                                for (let i = 0; i < item.images.length; i++) {
                                    images.push(item.images[i].name)
                                    fd.append('files', item.images[i]);
                                    item.images[i] = item.images[i].name
                                }
                            }
                            fd.append('homeViewNew', JSON.stringify(list))
                            fd.append('client', props.item.email)
                            fd.append('property', option.name)

                            context.apiReducer('addHomeViewNew', fd, {})
                                .then((response) => {
                                    props.edit({ choices: "PropertyHomeViewSuccess" })
                                })
                        }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "PropertyHomeViewNewModify") {
        // console.log(props.item, "props.item")
        // console.log(option, "option")
        return (
            <div className={"overlay-modal"}>
                <div onClick={close} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2>Modifier la fiche Home de {props.item.name}</h2>
                            </div>
                        </div>
                        <ModifyHomeViewNew
                            burl={burl}
                            data={option.homeViewNew}
                            private={option.homeViewNewPrivate}
                            send={(list, privateList) => {
                                const fd = new FormData()
                                for (const item of list) {
                                    const images = []
                                    for (let i = 0; i < item.images.length; i++) {
                                        if (typeof item.images[i] === "object") {
                                            images.push(item.images[i].name)
                                            fd.append('files', item.images[i]);
                                            item.images[i] = item.images[i].name
                                        } else {
                                            fd.append('files', item.images[i]);
                                            item.images[i] = item.images[i].replace(/^.*\/(.*)$/, "$1")
                                        }
                                        // console.log(item.images[i], "item.images[i]")
                                    }
                                }
                                fd.append('homeViewNew', JSON.stringify(list))
                                fd.append('homeViewNewPrivate', JSON.stringify(privateList))
                                fd.append('client', props.item.email)
                                fd.append('property', option.name)

                                // for (const entry of fd) {
                                //     console.log(entry, "entry");
                                // }

                                context.apiReducer('addHomeViewNew', fd, {})
                                    .then((response) => {
                                        // set the new home view to the option
                                        option.homeViewNew = [JSON.stringify(list)]
                                        option.homeViewNewPrivate = [JSON.stringify(privateList)]
                                        props.edit({ choices: "PropertyHomeViewModifySuccess" })
                                    })
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "PropertyHomeView") {

        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col-8"}>
                                <h2 className={""}>fiche Home de {props.item.name} - {option.name}</h2>
                            </div>
                            <div className={"col-4 gap-2 d-flex justify-content-end align-items-center"}>
                                <HomeViewPDF title={`Home-${props.item.name}-${option.name}`} data={option.homeView} />
                                <button className={"btn btn-warning"} onClick={() => {
                                    const object = props.item
                                    object.choices = "PropertyHomeViewModify"
                                    props.edit(object)
                                }}>
                                    <BsPencilSquare />
                                </button>
                            </div>
                        </div>
                        <HomeView data={option.homeView} />
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "PropertyHomeViewNew") {
        // console.log(option, "option")
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col-8"}>
                                <h2 className={""}>fiche Home V2 de {props.item.name} - {option.name}</h2>
                            </div>
                            <div className={"col-4 gap-2 d-flex justify-content-end align-items-center"}>
                                <HomeViewNewPDF title={`Home-${props.item.name}-${option.name}`} data={option.homeViewNew} />
                                <button className={"btn btn-warning"} onClick={() => {
                                    const object = props.item
                                    object.choices = "PropertyHomeViewNewModify"
                                    props.edit(object)
                                }}>
                                    <BsPencilSquare />
                                </button>
                            </div>
                        </div>
                        <HomeViewNew data={option.homeViewNew} private={option.homeViewNewPrivate} />
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "PropertyHomeViewSuccess") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Votre Fiche home a bien été ajouté</h2>
                                <p className={"form-text light"}>Voulez vous configurer d'autres éléments pour cet
                                    propriété ? </p>
                            </div>
                        </div>
                        <p>

                        </p>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            let value = ""
                            for (const i of e.target) {
                                if (i.checked) value = i.value
                            }
                            props.edit({ choices: value })
                        }}>
                            <div className="mb-3 list-unstyled components">
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="Booking"
                                        value="Booking"
                                    />
                                    <label className="form-check-label" htmlFor="Booking"> Bloquage </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="Intervention"
                                        value="Intervention"
                                    />
                                    <label className="form-check-label" htmlFor="Intervention"> Intervention </label>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-12">
                                    <button type={"submit"} className={"btn btn-light w-100"}>Continuer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "PropertyHomeViewModifySuccess") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Votre Fiche home a bien été modifiée</h2>
                            </div>
                        </div>
                        <p>
                            <form onSubmit={(e) => {
                                e.preventDefault()
                                let value = "PropertyHomeViewNew"
                                props.edit({ choices: value })
                            }}>
                                <div className={"row"}>
                                    <div className="mb-3 col-12">
                                        <button type={"submit"} className={"btn btn-light w-100"}>Continuer</button>
                                    </div>
                                </div>
                            </form>

                        </p>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "PropertySuccess") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Votre propriété a bien été ajouté</h2>
                                <p className={"form-text light"}>Voulez vous configurer d'autres éléments pour cet
                                    propriété ? </p>
                            </div>
                        </div>
                        <p>

                        </p>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            let value = ""
                            for (const i of e.target) {
                                if (i.checked) value = i.value
                            }
                            props.edit({ choices: value })
                        }}>
                            <div className="mb-3 list-unstyled components">
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="Booking"
                                        value="Booking"
                                    />
                                    <label className="form-check-label" htmlFor="Booking"> Bloquage </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="Intervention"
                                        value="Intervention"
                                    />
                                    <label className="form-check-label" htmlFor="Intervention"> Intervention </label>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-12">
                                    <button type={"submit"} className={"btn btn-light w-100"}>Continuer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "PropertyModifySuccess") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Votre propriété a bien été modifié</h2>
                                <p className={"form-text light"}>Voulez vous configurer d'autres éléments pour cet
                                    propriété ? </p>
                            </div>
                        </div>
                        <p>

                        </p>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            let value = ""
                            for (const i of e.target) {
                                if (i.checked) value = i.value
                            }
                            props.edit({ choices: value })
                        }}>
                            <div className="mb-3 list-unstyled components">
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="Booking"
                                        value="Booking"
                                    />
                                    <label className="form-check-label" htmlFor="Booking"> Bloquage </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="Intervention"
                                        value="Intervention"
                                    />
                                    <label className="form-check-label" htmlFor="Intervention"> Intervention </label>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-12">
                                    <button type={"submit"} className={"btn btn-light w-100"}>Continuer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    //TEAM

    if (props.item.choices === "Equipe") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={close} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Ajouter un nouveau colaborateur</h2>
                                <p className={"form-text light"}>Ajouter les informations necessaires</p>
                            </div>
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            let object = {}
                            for (const input of e.target) {
                                if (input.value) {
                                    const keys = input.name.split(".").reverse()
                                    let entry = { [keys[0]]: input.value }
                                    keys.shift()
                                    for (const key of keys) {
                                        entry = { [key]: entry }
                                    }
                                    object = mergeDeep(object, entry)
                                }
                            }
                            setLoading(true)
                            context.apiReducer('addTeam', object, { name: obj })
                                .then(() => {
                                    setLoading(false)
                                    props.edit({ choices: "EquipeSuccess" })
                                })
                                .catch(() => {
                                    setLoading(false)
                                })
                        }}>
                            <div className={"row"}>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="lastName" className="form-label">Nom</label>
                                    <input type="text" className="form-control" id="lastName" name="lastName"
                                        aria-describedby="start" required />
                                </div>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="name" className="form-label">Prénom</label>
                                    <input type="text" className="form-control" id="name" name="name"
                                        aria-describedby="end" required />
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="col-6 col-md-6">
                                    <div className={"mb-3"}>
                                        <label htmlFor="phone" className="form-label">Téléphone</label>
                                        <input type="phone" className="form-control" id="phone" name="phone"
                                            aria-describedby="end" required />
                                    </div>
                                </div>
                                <div className="col-6 col-md-6">
                                    <div className={"mb-3"}>
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input type="email" className="form-control" id="email" name="email"
                                            aria-describedby="end" required />
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-12">
                                    <SendButton type={"submit"} className={"btn btn-light w-100"}
                                        loading={loading}>Envoyer</SendButton>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "EquipeModify") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={close} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Modifier {props.item.lastName} {props.item.name} </h2>
                                <p className={"form-text light"}>Modifiez les informations necessaires</p>
                            </div>
                            <div className={"col-6 d-flex justify-content-end align-items-center"}>
                                <button className={"btn btn-danger"} onClick={() => {
                                    context.setError({
                                        level: 'danger',
                                        message: 'Êtes vous sur de vouloir supprimer ' + props.item.lastName + " " + props.item.name + " ?",
                                        btn1Text: 'Revenir',
                                        btn2Text: 'Supprimer',
                                        btn2Action: () => {
                                            context.apiReducer('modifyTeam', { archive: true }, { email: props.item.email })
                                                .then(() => {
                                                    props.edit({ choices: "EquipeDeleteSuccess" })
                                                })
                                        }
                                    })
                                }}>
                                    <BsFillTrashFill />
                                </button>
                            </div>
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            let object = {}
                            for (const input of e.target) {
                                if (input.value) {
                                    const keys = input.name.split(".").reverse()
                                    let entry = { [keys[0]]: input.value }
                                    keys.shift()
                                    for (const key of keys) {
                                        entry = { [key]: entry }
                                    }
                                    object = mergeDeep(object, entry)
                                }
                            }
                            setLoading(true)
                            context.apiReducer('modifyTeam', object, { email: props.item.email })
                                .then(() => {
                                    setLoading(false)
                                    props.edit({ choices: "EquipeModifySuccess" })
                                })
                                .catch(() => {
                                    setLoading(false)
                                })
                        }}>
                            <div className={"row"}>
                                <div className="col-6 col-md-6">
                                    <div className={"mb-3"}>
                                        <label htmlFor="phone" className="form-label">Téléphone</label>
                                        <input type="phone" className="form-control"
                                            id="phone" name="phone"
                                            defaultValue={props.item.phone}
                                            aria-describedby="end" required />
                                    </div>
                                </div>
                                <div className="col-6 col-md-6">
                                    <div className={"mb-3"}>
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input type="email" className="form-control"
                                            id="email" name="email"
                                            defaultValue={props.item.email}
                                            aria-describedby="end" required />
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-12">
                                    <SendButton type={"submit"} loading={loading}>Envoyer</SendButton>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "EquipeSuccess") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Votre utilisateur a bien été ajouté</h2>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="mb-3 col-12">
                                <button type={"button"} onClick={() => {
                                    props.edit({ choices: "" })
                                }} className={"btn btn-light w-100"}>Revenir au planning
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "EquipeModifySuccess") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Votre utilisateur a bien été modifié</h2>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="mb-3 col-12">
                                <button type={"button"} onClick={() => {
                                    props.edit({ choices: "" })
                                }} className={"btn btn-light w-100"}>Revenir au planning
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "EquipeDeleteSuccess") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>L'utilisateur a bien été supprimé</h2>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="mb-3 col-12">
                                <button type={"button"} onClick={() => {
                                    props.edit({ choices: "" })
                                }} className={"btn btn-light w-100"}>Revenir
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    //PROVIDERS

    if (props.item.choices === "Providers-new") {
        const itemProps = {
            element, setElement, setOption, option, propsContext: context, close, props, refresh,
            BsFillTrashFill, mergeDeep, SendButton, loading, setLoading, obj
        }
        return (
            <ProvidersAdd {...itemProps} />
        )
        // return (
        //     <div className={"overlay-modal"}>
        //         <div onClick={close} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
        //             <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
        //                 <div className={"row mb-3 sticky-top border-bottom bg-success"}>
        //                     <div className={"col"}>
        //                         <h2 className={""}>Ajouter un nouveau prestatataire</h2>
        //                         <p className={"form-text light"}>Ajouter les informations necessaires</p>
        //                     </div>
        //                 </div>
        //                 <form onSubmit={(e) => {
        //                     e.preventDefault()
        //                     let object = {}
        //                     for (const input of e.target) {
        //                         if (input.value) {
        //                             const keys = input.name.split(".").reverse()
        //                             let entry = { [keys[0]]: input.value }
        //                             keys.shift()
        //                             for (const key of keys) {
        //                                 entry = { [key]: entry }
        //                             }
        //                             object = mergeDeep(object, entry)
        //                         }
        //                     }
        //                     object.speciality = element.list

        //                     setLoading(true)
        //                     context.apiReducer('addProvider', object, { name: obj })
        //                         .then(() => {
        //                             setElement({ list: [] })
        //                             setOption(null)
        //                             setLoading(false)
        //                             props.edit({ ...object, choices: "ProvidersAddSuccess" })
        //                         })
        //                         .catch(() => {
        //                             setLoading(false)
        //                         })
        //                 }}>
        //                     <div className={"row"}>
        //                         <div className="mb-3 col-6 col-md-6">
        //                             <label htmlFor="name" className="form-label">Nom</label>
        //                             <input type="text" className="form-control" id="name" name="name"
        //                                 aria-describedby="start" required />
        //                         </div>
        //                         <div className="mb-3 col-6 col-md-6">
        //                             <label htmlFor="companyId" className="form-label">Siret</label>
        //                             <input type="text" className="form-control" id="companyId" name="companyId"
        //                                 aria-describedby="end" />
        //                         </div>
        //                     </div>
        //                     <div className={"row"}>
        //                         <div className="col-6 col-md-6">
        //                             <div className={"mb-3"}>
        //                                 <label htmlFor="phone" className="form-label">Téléphone</label>
        //                                 <input type="phone" className="form-control" id="phone" name="phone"
        //                                     aria-describedby="end" required />
        //                             </div>
        //                         </div>
        //                         <div className="col-6 col-md-6">
        //                             <div className={"mb-3"}>
        //                                 <label htmlFor="email" className="form-label">Email</label>
        //                                 <input type="email" className="form-control" id="email" name="email"
        //                                     aria-describedby="end" />
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div className={"row"}>
        //                         <h3>Commission</h3>
        //                         <div className="col-6 col-md-6">
        //                             <div className={"mb-3"}>
        //                                 <label htmlFor="commission.value" className="form-label">Valeur</label>
        //                                 <input type="number" className="form-control" id="commission.value"
        //                                     name="commission.value" min={0} aria-describedby="end" />
        //                             </div>

        //                         </div>
        //                         <div className="col-6 col-md-6">
        //                             <div className={"mb-3"}>

        //                                 <label htmlFor="commission.type" className="form-label">Type</label>
        //                                 <select className="form-select" id="commission.type"
        //                                     defaultValue={"€"}
        //                                     name="commission.type" aria-describedby="end">
        //                                     <option>€</option>
        //                                     <option>%</option>
        //                                 </select>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div className={"row"}>
        //                         <h3>Spécialités</h3>
        //                         <ul className={"px-2"}>
        //                             {
        //                                 element.list.map((item, index) => {
        //                                     return (
        //                                         <button type="button"
        //                                             className="btn btn-light w-100 mt-1 d-flex justify-content-between"
        //                                             onClick={() => {
        //                                                 const list = element.list
        //                                                 element.list.splice(index, 1)
        //                                                 setElement({ list: list })
        //                                                 refresh()
        //                                             }}>
        //                                             {item.speciality} <BsFillTrashFill />
        //                                         </button>
        //                                     )
        //                                 })
        //                             }
        //                         </ul>
        //                         <div className="mb-3 d-flex d-inline">
        //                             <input type="text" className="form-control" value={option} aria-describedby="end"
        //                                 onChange={(e) => {
        //                                     setOption(e.target.value)
        //                                 }} />
        //                             <button type="button" className="btn btn-light" onClick={() => {
        //                                 setElement({ list: element.list.concat({ speciality: [option] }) })
        //                                 setOption("")
        //                             }}> +
        //                             </button>
        //                         </div>
        //                     </div>
        //                     <div className={"row"}>
        //                         <div className={"mb-3"}>
        //                             <h3>Adresse de facturation</h3>
        //                             <div className="mb-3">
        //                                 <label htmlFor="address.zip" className="form-label">Code
        //                                     postal</label>
        //                                 <input type="text" className="form-control" id="address.zip"
        //                                     name="address.zip" aria-describedby="end" />
        //                             </div>
        //                             <div className="mb-3">
        //                                 <label htmlFor="address.city"
        //                                     className="form-label">Ville</label>
        //                                 <input type="text" className="form-control" id="address.city"
        //                                     name="address.city" aria-describedby="end" />
        //                             </div>
        //                             <div className="mb-3">
        //                                 <label htmlFor="address.road" className="form-label">Rue</label>
        //                                 <input type="text" className="form-control" id="address.road"
        //                                     name="address.road" aria-describedby="end" />
        //                             </div>
        //                             <div className="mb-3">
        //                                 <label htmlFor="address.number"
        //                                     className="form-label">Numéro</label>
        //                                 <input type="text" className="form-control" id="address.number"
        //                                     name="address.number" aria-describedby="end" />
        //                             </div>
        //                             <div className="mb-3">
        //                                 <label htmlFor="address.other"
        //                                     className="form-label">Autres informations</label>
        //                                 <input type="text" className="form-control" id="address.other"
        //                                     name="address.other" aria-describedby="end" />
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div className="mb-3">
        //                         <label htmlFor="observation" className="form-label">Observation</label>
        //                         <textarea className="form-control" rows={3}
        //                             id="observation" name="observation"
        //                             aria-describedby="start" />
        //                     </div>
        //                     <div className={"row"}>
        //                         <div className="mb-3 col-12">
        //                             <SendButton type={"submit"} loading={loading}>Envoyer</SendButton>
        //                         </div>
        //                     </div>
        //                 </form>
        //             </div>
        //         </div>
        //     </div>
        // )


    }

    if (props.item.choices === "ProvidersModify") {
        if (element?.list?.length === 0 && props?.item?.activity > 0) {
            setElement({ list: props.item.activity })
        }
        const itemProps = {
            element, setElement, setOption, option, propsContext: context, close, props, refresh,
            BsFillTrashFill, mergeDeep, SendButton, loading, setLoading
        }
        return (
            <ProvidersModify {...itemProps} />
        )
    }

    if (props.item.choices === "ProvidersDelete") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Le prestataire {props.item.name} a bien été supprimé</h2>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="mb-3 col-12">
                                <button type={"button"} onClick={() => {
                                    props.edit({ choices: "" })
                                }} className={"btn btn-light w-100"}>Quitter le menu contextuel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "ProvidersAddSuccess") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Le prestataire {props.item.name} a bien été ajouté</h2>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="mb-3 col-12">
                                <button type={"button"} onClick={closeInstant} className={"btn btn-light w-100"}>Quitter
                                    le menu contextuel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "ProvidersModifySuccess") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Le prestataire {props.item.name} a bien été modifié</h2>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="mb-3 col-12">
                                <button type={"button"} onClick={closeInstant} className={"btn btn-light w-100"}>Quitter
                                    le menu contextuel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "ProvidersDeleteSuccess") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Le prestataire {props.item.name} a bien été archivé</h2>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="mb-3 col-12">
                                <button type={"button"} onClick={closeInstant} className={"btn btn-light w-100"}>Quitter
                                    le menu contextuel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    //BOOKINGS

    if (props.item.platform && !props.item.choices) {
        /**
         * TODO : RE WORK to match the booking's creation overlay's parts (prices ...) + check fore the platform pb and child numbers (bugging parts seen during the summer)
         * */
        let inventory = ""
        if (props.item.inventory) inventory = JSON.parse(props.item.inventory)

        return (
            <div className={"overlay-modal"}>
                <div onClick={close} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div
                            className={"row mb-3 sticky-top border-bottom bg-" + (props.item.platform ? props.item.platform : "light")}>
                            <div className={"col-6"}>
                                <h2 className={""}>{props.item.platform}</h2>
                                <p className={"form-text"}>{props.item.id}</p>
                            </div>
                            <div className={"col-6 d-flex justify-content-end align-items-center"}>
                                {
                                    (inventory === "") ? (
                                        <button className={"btn btn-light me-3"} onClick={() => {
                                        }}>
                                            <BsClipboardX /> Pas d'état des lieux
                                        </button>
                                    ) : (
                                        <button className={"btn btn-light me-3"} onClick={() => {
                                            const object = props.item
                                            object.choices = "BookingShowInventory"
                                            props.edit(object)
                                        }}>
                                            <BsClipboardCheck /> État des lieux
                                        </button>
                                    )
                                }

                                <button className={"btn btn-light me-3"} onClick={() => {
                                    const object = props.item
                                    object.choices = "BookingInterventionAdd"
                                    props.edit(object)
                                }}>
                                    <BsCalendar /> Interventions Clefs
                                </button>
                                <button className={"btn btn-danger"} onClick={() => {
                                    context.setError({
                                        level: 'danger',
                                        message: 'Êtes vous sur de vouloir supprimer ce bloquage ?',
                                        btn1Text: 'Revenir',
                                        btn2Text: 'Supprimer',
                                        btn2Action: () => {
                                            context.apiReducer('modifyBooking', { cancelled: true }, { id: props.item.id })
                                                .then(() => {
                                                    props.edit({ choices: "platformDelete" })
                                                })
                                        }
                                    })
                                }}>
                                    <BsFillTrashFill />
                                </button>
                            </div>
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            let object = {}
                            for (const input of e.target) {
                                if (input.value) {
                                    const keys = input.name.split(".").reverse()
                                    let entry = { [keys[0]]: input.value }
                                    keys.shift()
                                    for (const key of keys) {
                                        entry = { [key]: entry }
                                    }
                                    object = mergeDeep(object, entry)
                                }
                            }
                            setLoading(true)
                            context.apiReducer('modifyBooking', object, { id: props.item.id })
                                .then(() => {
                                    setLoading(false)
                                    props.edit({ choices: "platformSuccess" })
                                })
                                .catch(() => {
                                    setLoading(false)
                                })
                        }}>
                            {!["airbnb", "booking", "homeaway", "gdf"].includes(props.item.platform) ?
                                <div className={"row"}>
                                    <div className="mb-3">
                                        <select name={"platform"} id={"platform"}
                                            className="form-select"
                                            defaultValue={props.item.platform}
                                            required>
                                            <option value={"unavailable"}>Indisponibilité générale</option>
                                            <option value={"back"}>Retour propriétaire</option>
                                            <option value={"rent"}>Location directe</option>
                                            <option value={"Rent-Client"}>Réservation via propriétaire</option>
                                        </select>
                                    </div>
                                </div>
                                : null
                            }
                            <div className={"row"}>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="dates.start" className="form-label">Date de début</label>
                                    <input type="date" className="form-control"
                                        name="dates.start" id="dates.start"
                                        aria-describedby="dates.start"
                                        defaultValue={toInputDate(props.item.dates.start)} />
                                    <div id="start" className="form-text">Date d'arrivée du client.
                                    </div>
                                </div>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="dates.end" className="form-label">Date de fin</label>
                                    <input type="date" className="form-control"
                                        name="dates.end" id="dates.end"
                                        aria-describedby="dates.end"
                                        defaultValue={toInputDate(props.item.dates.end)} />
                                    <div id="end" className="form-text">Date de départ du client.
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="description" className="form-label">Description</label>
                                <textarea className={"form-control"}
                                    name="description" id={"description"}
                                    aria-describedby="Description" contenteditable="true" defaultValue={props.item.description} />
                                <div id="descriptionHelp" className="form-text">Description visible uniquement par le
                                    bureau
                                    MyPacome.
                                </div>
                            </div>
                            {!["unavailable", "back", "Rent-Client"].includes(option) ?
                                <>
                                    {/* Prices */}
                                    <div className={"row"}>
                                        <h3>Prix</h3>
                                        <div className="col-6 col-md-6">
                                            <label className="form-label">Prix de la nuitée</label>
                                            <div className="input-group mb-0 w-100">
                                                <span className={"input-group-text"}>€</span>
                                                <input type={"number"} className={"form-control"}
                                                    id={"prices.nightTTC"} name={"prices.nightTTC"}
                                                    step={".01"} min={0}
                                                    defaultValue={toTTC(props.item.prices.night)}
                                                    onChange={(e) => {
                                                        changeTaxes(e.target.value, 1.20, "prices.night")
                                                    }}
                                                    aria-label={"Prix de la nuitée toute taxe comprise"} />
                                                <span className={"input-group-text"}>TTC</span>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <div className={"mb-3"}>
                                                <label htmlFor="prices.cleaning" className="form-label">Prix du
                                                    ménage</label>
                                                <div className="input-group mb-0 w-100">
                                                    <span className={"input-group-text"}>€</span>
                                                    <input type={"number"} className={"form-control"}
                                                        id={"prices.cleaningTTC"} name={"prices.cleaningTTC"}
                                                        step={".01"} min={0}
                                                        defaultValue={toTTC(props.item.prices.cleaning)}
                                                        onChange={(e) => {
                                                            changeTaxes(e.target.value, 1.20, "prices.cleaning")
                                                        }}
                                                        aria-label={"Prix du ménage toute taxe comprise"} />
                                                    <span className={"input-group-text"}>TTC</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <div className="input-group mb-3 w-100">
                                                <span className={"input-group-text"}>€</span>
                                                <input type={"number"} className={"form-control"}
                                                    id={"prices.night"} name={"prices.night"}
                                                    step={".01"} min={0} defaultValue={props.item.prices.night}
                                                    onChange={(e) => {
                                                        changeTaxes(e.target.value, 0.833333, "prices.nightTTC")
                                                    }}
                                                    aria-label={"Prix de la nuitée Hors Taxe"} />
                                                <span className={"input-group-text"}>HT</span>
                                                <DescriptionConditional value={props.item.prices.night !== undefined} />

                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <div className="input-group mb-0 w-100">
                                                <span className={"input-group-text"}>€</span>
                                                <input type={"number"} className={"form-control"}
                                                    id={"prices.cleaning"} name={"prices.cleaning"}
                                                    step={".01"} min={0} defaultValue={props.item.prices.cleaning}
                                                    onChange={(e) => {
                                                        changeTaxes(e.target.value, 1.20, "prices.cleaningTTC")
                                                    }}
                                                    aria-label={"Prix du ménage hors taxe"} />
                                                <span className={"input-group-text"}>HT</span>
                                                <DescriptionConditional
                                                    value={props.item.prices.cleaning !== undefined} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Bail and civic responsability */}
                                    <div className={"row"}>
                                        <h3>Caution et responsabilité civile</h3>
                                        <div className="col-6 col-md-6">
                                            <div className={"mb-3"}>
                                                <select className="form-select" id={"bail.type"} name={"bail.type"}
                                                    defaultValue={props.item.bail.type}>
                                                    <option value={""} selected> Type de caution</option>
                                                    <option value={"S"}>Swikly</option>
                                                    <option value={"C"}>Cheques</option>
                                                </select>
                                            </div>
                                            <div className={"mb-3"}>
                                                <select className="form-select" id={"bail.paid"} name={"bail.paid"}
                                                    defaultValue={props.item.bail.paid}>
                                                    <option value={"N"} selected>Caution ?</option>
                                                    <option value={"R"}> Caution Réceptionnée</option>
                                                    <option value={false}>Non</option>
                                                    <option value={"W"}>En attente</option>
                                                </select>
                                                <DescriptionConditional
                                                    value={!["N", "W"].includes(props.item.bail.paid)}
                                                    text={"Une erreur sera émise tant que la caution n'aura pas été payée par le voyageur"} />
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <div className={"mb-3"}>
                                                <select className="form-select" id={"civicResponsibility"}
                                                    name={"civicResponsibility"}
                                                    defaultValue={props.item.civicResponsibility}>
                                                    <option value={"D"} selected> Responsabilité civile ?</option>
                                                    <option value={"V"}> Responsabilité civile réceptionnée
                                                    </option>
                                                    <option value={"W"}>En attente</option>
                                                    <option value={"N"}>Non applicable</option>
                                                </select>
                                                <DescriptionConditional value={!(["W", "D"].includes(props.item.civicResponsibility))}
                                                    text={"Une erreur sera émise tant que la responsabilité civile du locataire n'aura pas été reçu."} />
                                            </div>
                                        </div>
                                        <div className="mb-3 col-6 col-md-6">

                                        </div>
                                    </div>
                                    {/* Laundry */}
                                    <div className={"row"}>
                                        <div className={"mb-3"}>
                                            <h3>Linge</h3>
                                            <div className={"mb-3"}>
                                                <select className="form-select" name={"laundry.took"}
                                                    defaultValue={props.item.laundry.took}>
                                                    <option value={"C"} selected>Le locataire prend t-il le linge ?
                                                    </option>
                                                    <option value={"Y"}>Oui</option>
                                                    <option value={"W"}>En attente de réponse</option>
                                                    <option value={"R"}>Non</option>
                                                </select>
                                                <DescriptionConditional
                                                    value={!["C"].includes(props.item.laundry.took) || props.item.laundry.took === "W"} />
                                                {
                                                    props.item.laundry.took === "W" ? (
                                                        <DescriptionConditional
                                                            text={"Ce champ émettra une erreur en attendant la réponse du client."} />
                                                    ) : (<></>)
                                                }
                                            </div>
                                            <div className={"mb-3"}>
                                                <select className="form-select" name={"laundry.payed"}
                                                    defaultValue={props.item.laundry.payed}>
                                                    <option value={"N"} selected>Le linge est il payé ?</option>
                                                    <option value={"Y"}>Oui</option>
                                                    <option value={"W"}>En attente de payement</option>
                                                    <option value={"P"}>Payée par le propriétaire ou inclu dans le prix de la location</option>
                                                    <option value={"R"}>Non applicable</option>
                                                </select>
                                                <DescriptionConditional
                                                    value={!["N"].includes(props.item.laundry.payed)} />
                                                <DescriptionConditional
                                                    value={!["W"].includes(props.item.laundry.payed)} text={"Ce champ émettra une erreur en attendant le payement du client."} />
                                            </div>
                                            <div className={"mb-3"}>
                                                <label htmlFor="laundry.commandNumber" className="form-label">Numéro de
                                                    commande</label>
                                                <input type="text" className="form-control" id="laundry.commandNumber"
                                                    name="laundry.commandNumber"
                                                    defaultValue={props.item.laundry.commandNumber}
                                                    aria-describedby="end" />
                                            </div>
                                            <div className={"mb-3"}>
                                                <label htmlFor="laundry.priceTTC" className="form-label">Prix de la
                                                    commande de linge</label>
                                                <div className="input-group mb-0 w-100">
                                                    <span className={"input-group-text"}>€</span>
                                                    <input type={"number"} className={"form-control"}
                                                        id={"laundry.priceTTC"} name={"laundry.priceTTC"}
                                                        step={".01"} min={0}
                                                        defaultValue={toTTC(props.item.laundry.price)}
                                                        onChange={(e) => {
                                                            changeTaxes(e.target.value, 1.20, "laundry.price")
                                                        }}
                                                        aria-label={"Prix du linge toutes taxes comprises"} />
                                                    <span className={"input-group-text"}>TTC</span>
                                                </div>
                                                <div className="input-group mb-3 w-100">
                                                    <span className={"input-group-text"}>€</span>
                                                    <input type={"number"} className={"form-control"}
                                                        id={"laundry.price"} name={"laundry.price"}
                                                        step={".01"} min={0} defaultValue={props.item.laundry.price}
                                                        onChange={(e) => {
                                                            changeTaxes(e.target.value, 0.833333, "laundry.priceTTC")
                                                        }}
                                                        aria-label={"Prix du linge Hors Taxe"} />
                                                    <span className={"input-group-text"}>HT</span>
                                                </div>
                                                <DescriptionConditional
                                                    value={props.item.laundry.price !== undefined} />
                                            </div>
                                            <div className={"row"}>
                                                <div className="mb-3 col-6 col-md-6">
                                                    <label htmlFor="laundry.dates.start" className="form-label">Date de
                                                        réception du linge</label>
                                                    <input type="date" className="form-control" id="laundry.dates.start"
                                                        name="laundry.dates.start"
                                                        defaultValue={toInputDate(props.item.laundry.dates?.start)}
                                                        aria-describedby="start" />
                                                </div>
                                                <div className="mb-3 col-6 col-md-6">
                                                    <label htmlFor="laundry.dates.end" className="form-label">Date de
                                                        retour
                                                        du
                                                        linge</label>
                                                    <input type="date" className="form-control" id="laundry.dates.end"
                                                        name="laundry.dates.end"
                                                        defaultValue={toInputDate(props.item.laundry.dates?.end)}
                                                        aria-describedby="end" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Traveler */}
                                    <div className={"row"}>
                                        <div className={"mb-3"}>
                                            <h3>Locataires</h3>
                                            <div className={"row"}>
                                                <div className="mb-3 col-6 col-md-6">
                                                    <label htmlFor="people.adults" className="form-label">Nombre
                                                        d'adultes</label>
                                                    <input type="Number" min={1} className="form-control"
                                                        id="people.adults" name="people.adults"
                                                        defaultValue={props.item.people?.adults}
                                                        aria-describedby="end" />
                                                    <DescriptionConditional value={props.item.people.adults} />
                                                </div>
                                                <div className="mb-3 col-6 col-md-6">
                                                    <label htmlFor="people.kids" className="form-label">Nombre d'enfants</label>
                                                    <input type="Number" min={0} className="form-control"
                                                        id="people.kids" name="people.kids"
                                                        defaultValue={props.item.people?.kids}
                                                        aria-describedby="end" />
                                                    <DescriptionConditional
                                                        value={props.item.people.kids !== undefined} /></div>
                                            </div>
                                            <h5>Locataire principal</h5>
                                            <div className={"row"}>
                                                <div className="mb-3 col">
                                                    <label htmlFor="people.traveler.name"
                                                        className="form-label">Nom du locataire</label>
                                                    <input type="text" className="form-control"
                                                        id="people.traveler.name" name="people.traveler.name"
                                                        defaultValue={props.item.people.traveler.name}
                                                        aria-describedby="end" />
                                                    <DescriptionConditional value={props.item.people.traveler.name} />
                                                </div>
                                            </div>
                                            <div className={"row"}>
                                                <div className="mb-3 col-6 col-md-6">
                                                    <label htmlFor="people.traveler.phone"
                                                        className="form-label">Téléphone</label>
                                                    <input type="text" className="form-control"
                                                        id="people.traveler.phone" name="people.traveler.phone"
                                                        defaultValue={props.item.people.traveler?.phone}
                                                        aria-describedby="end" />
                                                    <DescriptionConditional value={props.item.people.traveler?.phone} />
                                                </div>
                                                <div className="mb-3 col-6 col-md-6">
                                                    <label htmlFor="people.traveler.email"
                                                        className="form-label">Email</label>
                                                    <input type="text" className="form-control"
                                                        id="people.traveler.email" name="people.traveler.email"
                                                        defaultValue={props.item.people.traveler.email}
                                                        aria-describedby="end" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : null
                            }
                            <div className={"row"}>
                                <div className="mb-3 col-12">
                                    <SendButton type={"submit"} className={"btn btn-success w-100"}
                                        loading={loading}>Envoyer</SendButton>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "BookingShowInventory") {
        return (
            <BookingInventory close={close} overlayProps={props} inventory={props.item.inventory} />
        )
    }

    if (props.item.choices === "Booking") {
        /**
         * TODO : Re work the client's list generation, right now it's regenerating at each render. Which is a best not a thing you would want
         */
        const list = []

        /**
         * Transform value of the current price selected to the tax-free or tax included one that depends of the rate selected.
         * Then attribut it to an input
         * @param {number} value The current value of the price
         * @param {number} rate Value of the factor dividing the value
         * @param {string} target Id of the input you want to switch the value
         * */

        for (const client of context.data.people.Client) {
            if (client.archive) continue
            for (const property of client.properties) {
                list.push({
                    name: client.lastName + " " + client.name[0] + " : " + property.name,
                    value: property.name
                })
            }
        }


        return (
            <div className={"overlay-modal"}>
                <div onClick={close} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Ajouter un bloquage</h2>
                                <p className={"form-text light"}>Faites un choix pour continuer</p>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="mb-3">
                                <select className="form-select" aria-label="Default select example"
                                    name={"property"}
                                    onChange={(e) => {
                                        setObj(e.target.value)
                                    }} required>
                                    <option value={{}} selected>Selectionnez un bien</option>
                                    {
                                        list.sort(function (a, b) {
                                            const textA = a.name.toUpperCase();
                                            const textB = b.name.toUpperCase();
                                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                        }).map((item) => {
                                            return (
                                                <option value={item.value}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            let object = {}
                            for (const input of e.target) {
                                if (input.value) {
                                    const keys = input.name.split(".").reverse()
                                    let entry = { [keys[0]]: input.value }
                                    keys.shift()
                                    for (const key of keys) {
                                        entry = { [key]: entry }
                                    }
                                    object = mergeDeep(object, entry)
                                }
                            }
                            if (!object.platform) return null
                            setLoading(true)
                            context.apiReducer('addBooking', object, { name: obj })
                                .then(() => {
                                    setLoading(false)
                                    props.edit({ choices: "BookingSuccess" })
                                })
                                .catch(() => {
                                    setLoading(false)
                                })
                        }}>
                            <div className={"row"}>
                                <div className="mb-3">
                                    <select className="form-select" onChange={(e) => {
                                        setOption(e.target.value)
                                    }} minLength={1} name={"platform"} required>
                                        <option selected disabled value={""}>Type de bloquage</option>
                                        <option value={"unavailable"}>Indisponibilité générale</option>
                                        <option value={"back"}>Retour propriétaire</option>
                                        <option value={"rent"}>Location directe</option>
                                        <option value={"Rent-Client"}>Réservation via propriétaire</option>
                                        <option value={"airbnb"}>Airbnb</option>
                                        <option value={"booking"}>Booking</option>
                                        <option value={"homeaway"}>Homeaway</option>
                                        <option value={"gdf"}>Gites de france</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="description" className="form-label">Description</label>
                                <textarea className="form-control" id="description" name="description"
                                    aria-describedby="start" />
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="start" className="form-label">Date de début</label>
                                    <input type="date" className="form-control" id="start" name="dates.start"
                                        aria-describedby="start" required />
                                    <div id="start" className="form-text">Date d'arrivée du locataire.
                                    </div>
                                </div>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="end" className="form-label">Date de fin</label>
                                    <input type="date" className="form-control" id="end" name="dates.end"
                                        aria-describedby="end" required />
                                    <div id="end" className="form-text">Date de départ du locataire.
                                    </div>
                                </div>
                            </div>
                            {!["unavailable", "back", "Rent-Client"].includes(option) ?
                                <>
                                    {/* Prices */}
                                    <div className={"row"}>
                                        <h3>Prix</h3>
                                        <div className="col-6 col-md-6">
                                            <label className="form-label">Prix de la nuitée</label>
                                            <div className="input-group mb-0 w-100">
                                                <span className={"input-group-text"}>€</span>
                                                <input type={"number"} className={"form-control"}
                                                    id={"prices.nightTTC"} name={"prices.nightTTC"}
                                                    step={".01"} min={0} defaultValue={0}
                                                    onChange={(e) => {
                                                        changeTaxes(e.target.value, 1.20, "prices.night")
                                                    }}
                                                    aria-label={"Prix de la nuitée toute taxe comprise"} />
                                                <span className={"input-group-text"}>TTC</span>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <div className={"mb-3"}>
                                                <label htmlFor="prices.cleaning" className="form-label">Prix du
                                                    ménage</label>
                                                <div className="input-group mb-0 w-100">
                                                    <span className={"input-group-text"}>€</span>
                                                    <input type={"number"} className={"form-control"}
                                                        id={"prices.cleaningTTC"} name={"prices.cleaningTTC"}
                                                        step={".01"} min={0} defaultValue={0}
                                                        onChange={(e) => {
                                                            changeTaxes(e.target.value, 1.20, "prices.cleaning")
                                                        }}
                                                        aria-label={"Prix du ménage toute taxe comprise"} />
                                                    <span className={"input-group-text"}>TTC</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <div className="input-group mb-3 w-100">
                                                <span className={"input-group-text"}>€</span>
                                                <input type={"number"} className={"form-control"}
                                                    id={"prices.night"} name={"prices.night"}
                                                    step={".01"} min={0} defaultValue={0}
                                                    onChange={(e) => {
                                                        changeTaxes(e.target.value, 0.833333, "prices.nightTTC")
                                                    }}
                                                    aria-label={"Prix de la nuitée Hors Taxe"} />
                                                <span className={"input-group-text"}>HT</span>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <div className="input-group mb-0 w-100">
                                                <span className={"input-group-text"}>€</span>
                                                <input type={"number"} className={"form-control"}
                                                    id={"prices.cleaning"} name={"prices.cleaning"}
                                                    step={".01"} min={0} defaultValue={0}
                                                    onChange={(e) => {
                                                        changeTaxes(e.target.value, 1.20, "prices.cleaningTTC")
                                                    }}
                                                    aria-label={"Prix du ménage hors taxe"} />
                                                <span className={"input-group-text"}>HT</span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Bail and civic responsability */}
                                    <div className={"row"}>
                                        <h3>Caution et responsabilité civile</h3>
                                        <div className="col-6 col-md-6">
                                            <div className={"mb-3"}>
                                                <select className="form-select" id={"bail.type"} name={"bail.type"}
                                                    defaultValue={""}>
                                                    <option value={""} selected> Type de caution</option>
                                                    <option value={"S"}>Swikly</option>
                                                    <option value={"C"}>Cheques</option>
                                                </select>
                                            </div>
                                            <div className={"mb-3"}>
                                                <select className="form-select" id={"bail.paid"} name={"bail.paid"}
                                                    defaultValue={"N"}>
                                                    <option value={"N"} selected>Caution ?</option>
                                                    <option value={"R"}> Caution Réceptionnée</option>
                                                    <option value={false}>Non</option>
                                                    <option value={"W"}>En attente</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <div className={"mb-3"}>
                                                <select className="form-select" id={"civicResponsibility"}
                                                    name={"civicResponsibility"} defaultValue={"D"}>
                                                    <option value={"D"} selected> Responsabilité civile ?</option>
                                                    <option value={"V"}> Responsabilité civile réceptionnée
                                                    </option>
                                                    <option value={"W"}>En attente</option>
                                                    <option value={"N"}>Non applicable</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-3 col-6 col-md-6">

                                        </div>
                                    </div>
                                    {/* Laundry */}
                                    <div className={"row"}>
                                        <div className={"mb-3"}>
                                            <h3>Linge</h3>
                                            <div className={"mb-3"}>
                                                <select className="form-select" name={"laundry.took"} defaultValue={"C"}>
                                                    <option value={"C"} selected>Le locataire prend t-il le linge ?
                                                    </option>
                                                    <option value={"Y"}>Oui</option>
                                                    <option value={"W"}>En attente de réponse</option>
                                                    <option value={"R"}>Non</option>
                                                </select>
                                            </div>
                                            <div className={"mb-3"}>
                                                <select className="form-select" name={"laundry.payed"} defaultValue={"N"}>
                                                    <option value={"N"} selected>Le linge est il payé ?</option>
                                                    <option value={"Y"}>Oui</option>
                                                    <option value={"W"}>En attente de payement</option>
                                                    <option value={"P"}>Payée par le propriétaire ou inclu dans le prix de la location</option>
                                                    <option value={"R"}>Non applicable</option>
                                                </select>
                                            </div>
                                            <div className={"mb-3"}>
                                                <label htmlFor="laundry.commandNumber" className="form-label">Numéro de
                                                    commande</label>
                                                <input type="text" className="form-control" id="laundry.commandNumber"
                                                    name="laundry.commandNumber"
                                                    aria-describedby="end" />
                                            </div>
                                            <div className={"mb-3"}>
                                                <label htmlFor="laundry.priceTTC" className="form-label">Prix de la
                                                    commande de linge</label>
                                                <div className="input-group mb-0 w-100">
                                                    <span className={"input-group-text"}>€</span>
                                                    <input type={"number"} className={"form-control"}
                                                        id={"laundry.priceTTC"} name={"laundry.priceTTC"}
                                                        step={".01"} min={0} defaultValue={0}
                                                        onChange={(e) => {
                                                            changeTaxes(e.target.value, 1.2, "laundry.price")
                                                        }}
                                                        aria-label={"Prix du linge toutes taxes comprises"} />
                                                    <span className={"input-group-text"}>TTC</span>
                                                </div>
                                                <div className="input-group mb-3 w-100">
                                                    <span className={"input-group-text"}>€</span>
                                                    <input type={"number"} className={"form-control"}
                                                        id={"laundry.price"} name={"laundry.price"}
                                                        step={".01"} min={0} defaultValue={0}
                                                        onChange={(e) => {
                                                            changeTaxes(e.target.value, 0.833333, "laundry.priceTTC")
                                                        }}
                                                        aria-label={"Prix du linge Hors Taxe"} />
                                                    <span className={"input-group-text"}>HT</span>
                                                </div>
                                            </div>
                                            <div className={"row"}>
                                                <div className="mb-3 col-6 col-md-6">
                                                    <label htmlFor="laundry.dates.start" className="form-label">Date de
                                                        réception du linge</label>
                                                    <input type="date" className="form-control" id="laundry.dates.start"
                                                        name="laundry.dates.start"
                                                        aria-describedby="start" />
                                                </div>
                                                <div className="mb-3 col-6 col-md-6">
                                                    <label htmlFor="laundry.dates.end" className="form-label">Date de
                                                        retour
                                                        du
                                                        linge</label>
                                                    <input type="date" className="form-control" id="laundry.dates.end"
                                                        name="laundry.dates.end"
                                                        aria-describedby="end" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Traveler */}
                                    <div className={"row"}>
                                        <div className={"mb-3"}>
                                            <h3>Locataires</h3>
                                            <div className={"row"}>
                                                <div className="mb-3 col-6 col-md-6">
                                                    <label htmlFor="people.adults" className="form-label">Nombre
                                                        d'adultes</label>
                                                    <input type="Number" min={1} className="form-control"
                                                        id="people.adults"
                                                        name="people.adults"
                                                        aria-describedby="end" />
                                                </div>
                                                <div className="mb-3 col-6 col-md-6">
                                                    <label htmlFor="people.kids" className="form-label">Nombre
                                                        d'enfants</label>
                                                    <input type="Number" min={0} className="form-control"
                                                        id="people.kids"
                                                        name="people.kids"
                                                        aria-describedby="end" />
                                                </div>
                                            </div>
                                            <h5>Locataire principal</h5>
                                            <div className={"row"}>
                                                <div className="mb-3 col-12 col-md-12">
                                                    <label htmlFor="people.traveler.name"
                                                        className="form-label">Prénom - Nom</label>
                                                    <input type="text" className="form-control"
                                                        id="people.traveler.name"
                                                        name="people.traveler.name"
                                                        aria-describedby="end" />
                                                </div>
                                            </div>
                                            <div className={"row"}>
                                                <div className="mb-3 col-6 col-md-6">
                                                    <label htmlFor="people.traveler.phone"
                                                        className="form-label">Téléphone</label>
                                                    <input type="text" className="form-control"
                                                        id="people.traveler.phone"
                                                        name="people.traveler.phone"
                                                        aria-describedby="end" />
                                                </div>
                                                <div className="mb-3 col-6 col-md-6">
                                                    <label htmlFor="people.traveler.email"
                                                        className="form-label">Email</label>
                                                    <input type="text" className="form-control"
                                                        id="people.traveler.email"
                                                        name="people.traveler.email"
                                                        aria-describedby="end" />
                                                </div>
                                            </div>
                                            <h6>Adresse du locataire principal</h6>
                                            <div className="mb-3">
                                                <label htmlFor="people.traveler.address.zip" className="form-label">Code
                                                    postal</label>
                                                <input type="text" className="form-control"
                                                    id="people.traveler.address.zip"
                                                    name="people.traveler.address.zip"
                                                    aria-describedby="end" />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="people.traveler.address.city"
                                                    className="form-label">Ville</label>
                                                <input type="text" className="form-control"
                                                    id="people.traveler.address.city"
                                                    name="people.traveler.address.city"
                                                    aria-describedby="end" />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="people.traveler.address.road"
                                                    className="form-label">Rue</label>
                                                <input type="text" className="form-control"
                                                    id="people.traveler.address.road"
                                                    name="people.traveler.address.road"
                                                    aria-describedby="end" />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="people.traveler.address.number"
                                                    className="form-label">Numéro</label>
                                                <input type="text" className="form-control"
                                                    id="people.traveler.address.number"
                                                    name="people.traveler.address.number"
                                                    aria-describedby="end" />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="people.traveler.address.country"
                                                    className="form-label">Pays</label>
                                                <input type="text" className="form-control"
                                                    id="people.traveler.address.country"
                                                    name="people.traveler.address.country"
                                                    aria-describedby="end" />
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : null
                            }
                            <div className={"row"}>
                                <div className="mb-3 col-12">
                                    <SendButton type={"submit"} className={"btn btn-light w-100"}
                                        loading={loading}>Envoyer</SendButton>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "BookingSuccess") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Votre bloquage a bien été ajouté</h2>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="mb-3 col-12">
                                <button type={"button"} onClick={() => {
                                    props.edit({ choices: "" })
                                }} className={"btn btn-light w-100"}>Retour au planning
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "BookingInterventionAdd") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={"text-capitalize"}>{props.item.platform} - {new Date(props.item.dates.start).toLocaleDateString()}</h2>
                                <p className={"light small"}>Ajouter les interventions clefs</p>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="col-12">
                                <form onSubmit={(e) => {
                                    e.preventDefault()
                                    let object = {}
                                    for (const input of e.target) {
                                        if (input.value) {
                                            const keys = input.name.split(".").reverse()
                                            let entry = { [keys[0]]: input.value }
                                            keys.shift()
                                            for (const key of keys) {
                                                entry = { [key]: entry }
                                            }
                                            object = mergeDeep(object, entry)
                                        }
                                    }

                                    //check in
                                    const start = new Date(props.item.dates.start)
                                    const ci = {
                                        date: {
                                            dateStart: new Date(start.getFullYear(), start.getMonth(), start.getDate(), 15, 0),
                                            movable: false,
                                            duration: "00:30"
                                        },
                                        description: {
                                            description: ["Check in : \n", "Nombre d'adultes : ", props.item.people?.adults, "\n"
                                                , "Nombre d'enfants : ", props.item.people?.kids, "\n"
                                                , "Nom du locataire : ", props.item.people?.traveler.name, "\n"
                                                , "Téléphone du locataire : ", props.item.people?.traveler.phone].join('')
                                        },
                                        people: element.list

                                    }

                                    //check out
                                    const end = new Date(props.item.dates.end)

                                    const co = {
                                        date: {
                                            dateStart: new Date(end.getFullYear(), end.getMonth(), end.getDate(), 11, 0),
                                            movable: false,
                                            duration: "00:30"
                                        },
                                        description: {
                                            description:
                                                ["Check out : \n", "Nombre d'adultes : ", props.item.people?.adults, "\n"
                                                    , "Nombre d'enfants : ", props.item.people?.kids, "\n"
                                                    , "Nom du locataire : ", props.item.people?.traveler.name, "\n"
                                                    , "Téléphone du locataire : ", props.item.people?.traveler.phone].join('')
                                        },
                                        people: element2.list
                                    }

                                    // cleaning
                                    const clean = {
                                        date: {
                                            dateStart: new Date(end.getFullYear(), end.getMonth(), end.getDate() + 1, 10, 0),
                                            movable: object.cleaning?.date?.movable,
                                            movableRange: object.cleaning?.date?.movableRange
                                        },
                                        description: { description: "Ménage après location" }
                                        ,
                                        people: element2.list
                                    }

                                    const objectToSend = {
                                        id: props.item.id,
                                        interventions: [ci, co, clean]
                                    }
                                    setLoading(true)
                                    context.apiReducer('addBookingInterventions', objectToSend)
                                        .then(() => {
                                            setElement({ list: [] })
                                            setElement2({ list: [] })
                                            setElement3({ list: [] })
                                            setLoading(false)
                                            props.edit({ choices: "InterventionSuccess" })
                                        })
                                        .catch((e) => {
                                            console.log(e)
                                            setLoading(false)
                                        })
                                }}>
                                    <div className={""}>
                                        <h2 className={"strong"}>Check In</h2>
                                        <div>
                                            <label className="form-label">Concierges</label>
                                            <div className={"input-group mb-3"}>
                                                <select className="form-select "
                                                    id={"check-in.people.people"} name={"check-in.people.people"}
                                                    defaultValue={""}>
                                                    <option value={""} />
                                                    <optgroup label="Concierges">
                                                        {
                                                            context.data.people.Concierge.map((concierge) => {
                                                                let disabled = concierge.contract.finished ?
                                                                    new Date(concierge.contract.finished) >= new Date(option)
                                                                    : false
                                                                if (element.list.some(el => el.people === concierge.id)) disabled = true
                                                                if (concierge.archive) return null
                                                                return (
                                                                    <option value={concierge.id}
                                                                        disabled={disabled}>
                                                                        {concierge.lastName} {concierge.name}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </optgroup>
                                                </select>
                                                <button className={"input-group-text"} type={"button"} onClick={() => {
                                                    if (!document.querySelector('select[id="check-in.people.people"]').value) return null
                                                    const people = document.querySelector('select[id="check-in.people.people"]').value
                                                    if (people && !element.list.some(el => el.people === people)) {
                                                        const object = element
                                                        object.list.push({ people: people })
                                                        document.querySelector('select[id="check-in.people.people"]').value = ""
                                                        setElement(object)
                                                        refresh()
                                                    }
                                                }}><BsPlus /></button>
                                            </div>
                                            <div className={"mb-3"}>
                                                <h5>Liste des intervenants</h5>
                                                <ul className={"list-group"}>
                                                    {
                                                        element.list.map((item) => {
                                                            const people = context.data.people.Concierge.find(el => el.id === item.people)
                                                            return <li
                                                                className={"list-group-item list-group-item-action d-flex justify-content-between"}
                                                                onClick={() => {
                                                                    const object = element
                                                                    object.list = object.list.filter(el => el.people !== item.people)
                                                                    setElement(object)
                                                                    refresh()
                                                                }}>
                                                                {people.lastName} {people.name}
                                                                <span
                                                                    className={""}><small>Cliquez pour supprimer</small></span>
                                                            </li>

                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={""}>
                                        <hr />
                                        <h2 className={"strong"}>Check out</h2>
                                        <div>
                                            <label className="form-label">Concierges</label>
                                            <div className={"input-group mb-3"}>
                                                <select className="form-select "
                                                    id={"check-out.people.people"} name={"check-out.people.people"}
                                                    defaultValue={""}>
                                                    <option value={""} />
                                                    <optgroup label="Concierges">
                                                        {
                                                            context.data.people.Concierge.map((concierge) => {
                                                                let disabled = concierge.contract.finished ?
                                                                    new Date(concierge.contract.finished) >= new Date(option)
                                                                    : false
                                                                if (element2.list.some(el => el.people === concierge.id)) disabled = true
                                                                if (concierge.archive) return null
                                                                return (

                                                                    <option value={concierge.id}
                                                                        disabled={disabled}>
                                                                        {concierge.lastName} {concierge.name}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </optgroup>

                                                </select>
                                                <button className={"input-group-text"} type={"button"} onClick={() => {
                                                    if (!document.querySelector('select[id="check-out.people.people"]').value) return null
                                                    const people = document.querySelector('select[id="check-out.people.people"]').value
                                                    if (people && !element2.list.some(el => el.people === people)) {
                                                        const object = element2
                                                        object.list.push({ people: people })
                                                        document.querySelector('select[id="check-out.people.people"]').value = ""
                                                        setElement2(object)
                                                        refresh()
                                                    }
                                                }}><BsPlus /></button>
                                            </div>
                                            <div className={"mb-3"}>
                                                <h5>Liste des intervenants</h5>
                                                <ul className={"list-group"}>
                                                    {
                                                        element2.list.map((item) => {
                                                            const people = context.data.people.Concierge.find(el => el.id === item.people)
                                                            return <li
                                                                className={"list-group-item list-group-item-action d-flex justify-content-between"}
                                                                onClick={() => {
                                                                    const object = element2
                                                                    object.list = object.list.filter(el => el.people !== item.people)
                                                                    setElement2(object)
                                                                    refresh()
                                                                }}>
                                                                {people.lastName} {people.name}
                                                                <span
                                                                    className={""}><small>Cliquez pour supprimer</small></span>
                                                            </li>

                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={""}>
                                        <hr />
                                        <h2 className={"strong"}>Ménage après location</h2>
                                        <div className={"row"}>
                                            <div className={"mb-3 col-6 col-md-6"}>
                                                <div className={"mb-3"}>
                                                    <label htmlFor="cleaning.date.movable" className="form-label">Déplacable
                                                        ?</label>
                                                    <select className="form-select" id={"cleaning.date.movable"}
                                                        name={"cleaning.date.movable"}
                                                        defaultValue={""} required>
                                                        <option value={""} selected />
                                                        <option value={true}>Oui</option>
                                                        <option value={false}>Non</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className={"mb-3 col-6 col-md-6"}>
                                                <div className={"mb-3"}>
                                                    <label htmlFor="cleaning.date.movableRange" className="form-label">Intervalle
                                                        de
                                                        déplacement</label>
                                                    <input type="number" className="form-control"
                                                        id="cleaning.date.movableRange"
                                                        name="cleaning.date.movableRange"
                                                        min={0}
                                                        aria-describedby="end" />

                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <label className="form-label">Concierges</label>
                                            <div className={"input-group mb-3"}>
                                                <select className="form-select "
                                                    id={"cleaning.people.people"} name={"cleaning.people.people"}
                                                    defaultValue={""}>
                                                    <option value={""} />
                                                    <optgroup label="Concierges">
                                                        {
                                                            context.data.people.Concierge.map((concierge) => {
                                                                let disabled = concierge.contract.finished ?
                                                                    new Date(concierge.contract.finished) >= new Date(option)
                                                                    : false
                                                                if (element3.list.some(el => el.people === concierge.id)) disabled = true
                                                                if (concierge.archive) return null
                                                                return (

                                                                    <option value={concierge.id}
                                                                        disabled={disabled}>
                                                                        {concierge.lastName} {concierge.name}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </optgroup>

                                                </select>
                                                <button className={"input-group-text"} type={"button"} onClick={() => {
                                                    if (!document.querySelector('select[id="cleaning.people.people"]').value) return null
                                                    const people = document.querySelector('select[id="cleaning.people.people"]').value
                                                    if (people && !element3.list.some(el => el.people === people)) {
                                                        const object = element3
                                                        object.list.push({ people: people })
                                                        document.querySelector('select[id="cleaning.people.people"]').value = ""
                                                        setElement3(object)
                                                        refresh()
                                                    }
                                                }}><BsPlus /></button>
                                            </div>
                                            <div className={"mb-3"}>
                                                <h5>Liste des intervenants</h5>
                                                <ul className={"list-group"}>
                                                    {
                                                        element3.list.map((item) => {
                                                            const people = context.data.people.Concierge.find(el => el.id === item.people)
                                                            return <li
                                                                className={"list-group-item list-group-item-action d-flex justify-content-between"}
                                                                onClick={() => {
                                                                    const object = element3
                                                                    object.list = object.list.filter(el => el.people !== item.people)
                                                                    setElement3(object)
                                                                    refresh()
                                                                }}>
                                                                {people.lastName} {people.name}
                                                                <span
                                                                    className={""}><small>Cliquez pour supprimer</small></span>
                                                            </li>

                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className="mb-3 col-12">
                                            <SendButton type={"submit"} className={"btn btn-light w-100"}
                                                loading={loading}>Envoyer</SendButton>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "platformSuccess") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Votre bloquage a bien été mis à jour.</h2>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="mb-3 col-12">
                                <button type={"button"} onClick={() => {
                                    props.edit({ choices: "" })
                                }} className={"btn btn-light w-100"}>Revenir au planning
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
    }

    if (props.item.choices === "platformDelete") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Votre bloquage a bien été supprimé.</h2>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="mb-3 col-12">
                                <button type={"button"} onClick={() => {
                                    props.edit({ choices: "" })
                                }} className={"btn btn-light w-100"}>Revenir au planning
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
    }

    //INTERVENTIONS

    if (props.item.choices === "Intervention") {
        const list = []
        for (const client of context.data.people.Client) {
            if (!client.archive) {
                for (const property of client.properties) {
                    list.push({
                        name: client.lastName + " " + client.name[0] + " : " + property.name,
                        value: property.name
                    })
                }
            }
        }

        return (
            <div className={"overlay-modal"}>
                <div onClick={close} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Ajouter une ou plusieurs interventions</h2>
                                <p className={"form-text light"}>Remplissez ces champs pour continuer</p>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="mb-3">
                                <select className="form-select" aria-label="Default select example"
                                    name={"property"}
                                    onChange={(e) => {
                                        setObj(e.target.value)
                                    }} required>
                                    <option value={{}} selected>Selectionnez un bien</option>
                                    {
                                        list.sort(function (a, b) {
                                            const textA = a.name.toUpperCase();
                                            const textB = b.name.toUpperCase();
                                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                        }).map((item) => {
                                            if (item.archive) return null
                                            return (
                                                <option value={item.value}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            let object = {}
                            for (const input of e.target) {
                                if (input.value) {
                                    const keys = input.name.split(".").reverse()
                                    let entry = { [keys[0]]: input.value }
                                    keys.shift()
                                    for (const key of keys) {
                                        entry = { [key]: entry }
                                    }
                                    object = mergeDeep(object, entry)
                                }
                            }
                            object.people = element.list
                            setLoading(true)
                            context.apiReducer('addIntervention', object, { name: obj })
                                .then(() => {
                                    setElement({ list: [] })
                                    setLoading(false)
                                    props.edit({ choices: "InterventionSuccess" })
                                })
                                .catch(() => {
                                    setLoading(false)
                                })
                        }}>
                            <div className={"mb-3"}>
                                <div className={"mb-3"}>
                                    <label htmlFor="type" className="form-label">Type d'intervention</label>
                                    <select className="form-select"
                                        id={"type"} name={"type"}
                                        onChange={(e) => {
                                            const el = element
                                            el.type = e.target.value
                                            setElement(el)
                                            refresh()
                                        }}
                                        defaultValue={""} required>
                                        <option value={""} selected />
                                        <option value={"R"}>Régulière (Emission par abonement)</option>
                                        <option value={"P"}>Préstation (Emission par devis ou formule)</option>
                                        <option value={"E"}>Externe (Emission par contrat d'apport de service)</option>
                                        <option value={"I"}>Interne (Emission par l'équipe )</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="description.description" className="form-label">Description</label>
                                <textarea className="form-control" id="description.description"
                                    name="description.description"
                                    aria-describedby="start" />
                            </div>
                            <div className={"row"}>

                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="date.dateStart" className="form-label">Date</label>
                                    <input type="datetime-local" className="form-control"
                                        step={900}
                                        onChange={(e) => {
                                            setOption(e.target.value)
                                        }}
                                        id="date.dateStart" name="date.dateStart"
                                        aria-describedby="start" required />
                                    <div id="start" className="form-text">Date et heure de début d'intervention.</div>
                                </div>

                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="date.duration" className="form-label">Durée</label>
                                    <input type="time" className="form-control"
                                        id="date.duration" name="date.duration"
                                        defaultValue={"01:00"}
                                        aria-describedby="end" required />
                                    <div id="end" className="form-text">Date de départ du locataire.
                                    </div>
                                </div>
                            </div>
                            {
                                element.type === "R" ?
                                    <div className={"mb-3"}>
                                        <div className={"mb-3"}>
                                            <label htmlFor="type" className="form-label">Récurrence</label>
                                            <select className="form-select"
                                                id={"recurrence"} name={"recurrence"}
                                                defaultValue={""} required>
                                                <option value={""} selected />
                                                <option value={"W"}>Hebdomadaire</option>
                                                <option value={"2W"}>Bi-mensuel</option>
                                                <option value={"M"}>Mensuel</option>
                                                <option value={"T"}>Trimestrielle</option>
                                            </select>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            <div className={"row"}>
                                <div className={"mb-3 col-6 col-md-6"}>
                                    <div className={"mb-3"}>
                                        <label htmlFor="date.movable" className="form-label">Déplacable ?</label>
                                        <select className="form-select" id={"date.movable"} name={"date.movable"}
                                            defaultValue={""} required>
                                            <option value={""} selected />
                                            <option value={true}>Oui</option>
                                            <option value={false}>Non</option>
                                        </select>
                                        <div id="end" className="form-text">Le concierge peut-il déplacer
                                            l'intervention.
                                        </div>
                                    </div>
                                </div>
                                <div className={"mb-3 col-6 col-md-6"}>
                                    <div className={"mb-3"}>
                                        <label htmlFor="date.movable" className="form-label">Intervalle de
                                            déplacement <small></small></label>
                                        <input type="number" className="form-control"
                                            id="date.movableRange" name="date.movableRange"
                                            min={0}
                                            aria-describedby="end" />
                                        <div id="end" className="form-text">
                                            (Nécessaire uniquement si l'intervention est déplaçable)
                                            Nombre de jours sur les quels le concierge peut déplacer l'intervention
                                            avant ou après la date initialement prévue.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {
                                    element.type === "I" ?
                                        <div>
                                            <label class="form-label">Equipe</label>
                                            <div className={"input-group mb-3"}>
                                                <select className={"form-select"}
                                                    id={"people.people"} name={"people.people"}
                                                    defaultValue={""}>
                                                    <option value="" selected="" />
                                                    <optgroup label="Equipe">
                                                        {
                                                            context.data.people.Equipe.map((equipe) => {
                                                                if (equipe.archive) return null
                                                                return (

                                                                    <option value={equipe.id}>
                                                                        {equipe.lastName} {equipe.name}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </optgroup>
                                                </select>
                                                <button className={"input-group-text"} type={"button"}
                                                    onClick={() => {
                                                        if (!document.querySelector('select[id="people.people"]').value) return null
                                                        const people = document.querySelector('select[id="people.people"]').value
                                                        if (people && !element.list.some(el => el.people === people)) {
                                                            const object = element
                                                            object.list.push({ people: people })
                                                            document.querySelector('select[id="people.people"]').value = ""
                                                            setElement(object)
                                                            refresh()
                                                        }
                                                    }}><BsPlus /></button>
                                            </div>
                                            <div className={"mb-3"}>
                                                <h5>Liste des intervenants</h5>
                                                <ul className={"list-group"}>
                                                    {
                                                        element.list.map((item) => {
                                                            const people = context.data.people.Equipe.find(el => el.id === item.people)
                                                            return <li
                                                                className={"list-group-item list-group-item-action d-flex justify-content-between"}
                                                                onClick={() => {
                                                                    const object = element
                                                                    object.list = object.list.filter(el => el.people !== item.people)
                                                                    setElement(object)
                                                                    refresh()
                                                                }}>
                                                                {people.lastName} {people.name}
                                                                <span
                                                                    className={""}><small>Cliquez pour supprimer</small></span>
                                                            </li>

                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>

                                        :
                                        <div>
                                            <label className="form-label">Concierges</label>
                                            <div className={"input-group mb-3"}>
                                                <select className="form-select "
                                                    id={"people.people"} name={"people.people"}
                                                    defaultValue={""}>
                                                    <option value={""} />
                                                    <optgroup label="Concierges">
                                                        {
                                                            context.data.people.Concierge.map((concierge) => {
                                                                let disabled = concierge.contract.finished ?
                                                                    new Date(concierge.contract.finished) >= new Date(option)
                                                                    : false
                                                                if (element.list.some(el => el.people === concierge.id)) disabled = true
                                                                if (concierge.archive) return null
                                                                return (

                                                                    <option value={concierge.id}
                                                                        disabled={disabled}>
                                                                        {concierge.lastName} {concierge.name}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </optgroup>

                                                </select>
                                                <button className={"input-group-text"} type={"button"}
                                                    onClick={() => {
                                                        if (!document.querySelector('select[id="people.people"]').value) return null
                                                        const people = document.querySelector('select[id="people.people"]').value
                                                        if (people && !element.list.some(el => el.people === people)) {
                                                            const object = element
                                                            object.list.push({ people: people })
                                                            document.querySelector('select[id="people.people"]').value = ""
                                                            setElement(object)
                                                            refresh()
                                                        }
                                                    }}><BsPlus /></button>
                                            </div>
                                            <div className={"mb-3"}>
                                                <h5>Liste des intervenants</h5>
                                                <ul className={"list-group"}>
                                                    {
                                                        element.list.map((item) => {
                                                            const people = context.data.people.Concierge.find(el => el.id === item.people)
                                                            return <li
                                                                className={"list-group-item list-group-item-action d-flex justify-content-between"}
                                                                onClick={() => {
                                                                    const object = element
                                                                    object.list = object.list.filter(el => el.people !== item.people)
                                                                    setElement(object)
                                                                    refresh()
                                                                }}>
                                                                {people.lastName} {people.name}
                                                                <span
                                                                    className={""}><small>Cliquez pour supprimer</small></span>
                                                            </li>

                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                }

                                <div className={"row"}>
                                    <div className="mb-3 col-12">
                                        <SendButton type={"submit"} className={"btn btn-light w-100"}
                                            loading={loading}>Envoyer</SendButton>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "InterventionBrief") {
        //TODO:[todo fait mais à tester]  enlever le dateModified et utiliser la norme iso et faire une fonction pr toutes les dates 
        let interventionDateIso = new Date(props.item.date?.dateStart).toISOString()
        const formattedDate = formatDate(interventionDateIso);
        const burl = context.getBurl()
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col-8"}>
                                <h2 className={""}>{formattedDate}</h2>
                                <p>
                                    Durée : {props.item.date?.duration.split(':').join('h')}
                                </p>
                            </div>
                            <div className={"col-4 d-flex justify-content-end align-items-center"}>
                                <button className={"btn btn-warning"} onClick={() => {
                                    const object = props.item
                                    object.choices = props.item.group ? "InterventionMultiple" : "InterventionModify"
                                    props.edit(object)
                                }}>
                                    <BsPencilSquare />
                                </button>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div>

                                <p>
                                    Description de l'intervention : {props.item.description?.description}
                                </p>
                            </div>
                        </div>
                        {props.item.people.map((people, index) => {
                            const user = context.findInterventionPeople(people.people)
                            const report = people.report ? people.report : "Pas de rapport d'intervention pour l'instant"
                            const finishDate = people.finishDate ? new Date(people.finishDate).toLocaleString() : "Intervention non validée pour l'instant."

                            return (
                                <div className={"row"} key={index + people.people}>
                                    <h4>{user.lastName} - {user.name}</h4>
                                    <button className={"btn btn-outline-warning mb-2"} type={"button"}
                                        hidden={people.finishDate !== undefined}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            const concierge = context.data.people.Concierge.filter((item) => item.id === people.people)[0]

                                            setOption({
                                                interventionId: props.item.id,
                                                peopleId: people.people,
                                                concierge: concierge
                                            })
                                            const object = props.item
                                            object.choices = "InterventionAddValidation"
                                            props.edit(object)
                                        }}>
                                        Valider à la place du concierge
                                    </button>
                                    <p>Rapport : {report}</p>
                                    <p>Date de validation : {finishDate}</p>
                                    <p hidden={people.photo?.length === 0}>Photo d'intervention : </p>
                                    <ImagesFits data={people.photo.map((photo => burl + '/' + photo))}
                                        onError={(e) => {
                                            e.target.onerror = null; // prevents looping
                                            e.target.src = "https://i.ibb.co/3NxyCrY/img-Not-Found.png";
                                        }}
                                        errorText={"texte d'erreur"} />
                                </div>
                            )
                        })}

                        <div className={"row"}>


                            <div className="mb-3 col-12">

                                <button type={"button"} onClick={() => {
                                    props.edit({ choices: "" })
                                }} className={"btn btn-light w-100"}>Retour au planning
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "InterventionMultiple") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col-8"}>
                                <h2 className={""}>Modifier l'intervention
                                    régulière {formatDate(new Date(props.item.date.dateStart).toISOString())} </h2>
                                <p className={"form-text light"}>{props.item.id}</p>
                            </div>
                            <div className={"col-4 d-flex justify-content-end align-items-center"}>
                                <button className={"btn btn-danger"} onClick={() => {
                                    context.setError({
                                        level: 'danger',
                                        message: "Êtes vous sur de vouloir annuler l'intervention ?",
                                        btn1Text: 'Revenir',
                                        btn2Text: 'Annuler l\'intervention',
                                        btn2ActionAvoidClose: true,
                                        btn2Action: () => {
                                            context.setError({
                                                level: 'danger',
                                                message: "Confirmer l'annulation de l'intervention du " + new Date(props.item.date.dateStart).toLocaleString() + " ?",
                                                btn1Text: 'Revenir',
                                                btn2Text: 'Annuler l\'intervention',
                                                btn2Action: () => {
                                                    context.apiReducer('modifyIntervention', {
                                                        date: { dateStart: props.item.date.dateStart },
                                                        cancelled: true
                                                    }, { id: props.item.id })
                                                        .then(() => {
                                                            props.edit({ choices: "InterventionModifySuccess" })
                                                        })
                                                }
                                            })
                                        },
                                        btn3Text: 'Supprimer le groupe d\'intervention',
                                        btn3hidden: false,
                                        btn3ActionAvoidClose: true,
                                        btn3Action: () => {
                                            context.setError({
                                                level: 'danger',
                                                message: "Confirmer la suppression du groupe d'intervention à partir de la date de l'intervention séléctionnée du " + new Date(props.item.date.dateStart).toLocaleString() + " ?",
                                                btn1Text: 'Revenir',
                                                btn2Text: 'Supprimer le groupe d\'intervention',
                                                btn2Action: () => {
                                                    context.apiReducer('modifyMultipleIntervention', (
                                                        {
                                                            date: { dateStart: props.item.date.dateStart, duration: props.item.date.duration },
                                                            cancelled: true
                                                        }
                                                    ), { group: props.item.group })
                                                        .then(() => {
                                                            props.edit({ choices: "InterventionMultipleModifySuccess" })
                                                        })
                                                        .catch((err) => {
                                                        })
                                                }
                                            })
                                        },
                                    })
                                }}>
                                    <BsFillTrashFill />
                                </button>
                            </div>
                        </div>
                        <p>

                        </p>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            let value = ""
                            for (const i of e.target) {
                                if (i.checked) value = i.value
                            }
                            const object = props.item
                            object.choices = value
                            props.edit(object)
                        }}>
                            <div className="mb-3 list-unstyled components">
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="InterventionModify"
                                        value="InterventionModify"
                                    />
                                    <label className="form-check-label" htmlFor="InterventionModify">Modifier
                                        uniquement
                                        cette intervention</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="choices"
                                        id="InterventionMultipleModify"
                                        value="InterventionMultipleModify"
                                    />
                                    <label className="form-check-label" htmlFor="InterventionMultipleModify">Modifier
                                        toutes les prochaines interventions</label>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-12">
                                    <button type={"submit"} className={"btn btn-light w-100"}>Continuer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "InterventionMultipleModify") {
        // Handle click on intervenant
        const handleIntervenantClick = (intervenant) => {
            const newIntervenants = currentIntervenants.filter((i) => i.people !== intervenant.people);
            setCurrentIntervenants(newIntervenants);
            const newElement = { ...element, list: newIntervenants };
            setElement(newElement);

        };
        if (element.list.length === 0 && props.item.people.length > 0) {
            const objTemp = element
            objTemp.list = props.item.people
            setElement(objTemp)
        }
        if (element.list[0]?.people !== props.item.people[0]?.people) {
            const objTemp2 = element
            objTemp2.list = props.item.people
            setElement(objTemp2)
        }

        return (
            <div className={"overlay-modal"}>
                <div onClick={close} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Modifier un groupe d'intervention</h2>
                                <p className={"form-text light"}>L'intervention sera modifiée à partir de de la date
                                    de
                                    l'intervention séléctionnée soit à partir
                                    du {formatDate(new Date(props.item.date.dateStart).toISOString())}</p>
                            </div>
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            let object = {}
                            for (const input of e.target) {
                                if (input.value) {
                                    const keys = input.name.split(".").reverse()
                                    let entry = { [keys[0]]: input.value }
                                    keys.shift()
                                    for (const key of keys) {
                                        entry = { [key]: entry }
                                    }
                                    object = mergeDeep(object, entry)
                                }
                            }
                            object.people = currentIntervenants;
                            object.date.dateStart = props.item.date.dateStart
                            setLoading(true)
                            context.apiReducer('modifyMultipleIntervention', object, { group: props.item.group })
                                .then(() => {
                                    setLoading(false)
                                    props.edit({ choices: "InterventionMultipleModifySuccess" })
                                })
                                .catch(() => {
                                    setLoading(false)
                                })
                        }}>
                            <div className="mb-3">
                                <label htmlFor="description.description" className="form-label">Description</label>
                                <textarea className="form-control" id="description.description"
                                    name="description.description"
                                    defaultValue={props.item.description.description}
                                    aria-describedby="start" />
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="date.duration" className="form-label">Durée</label>
                                    <input type="time" className="form-control"
                                        id="date.duration" name="date.duration"
                                        defaultValue={props.item.date.duration}
                                        aria-describedby="end" required />
                                    <div id="end" className="form-text">Durée en heures et en minutes de
                                        l'intervention.
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"mb-3 col-6 col-md-6"}>
                                    <div className={"mb-3"}>
                                        <label htmlFor="date.movable" className="form-label">Déplacable ?</label>
                                        <select className="form-select" id={"date.movable"} name={"date.movable"}
                                            defaultValue={props.item.date.movable} required>
                                            <option value={""} selected />
                                            <option value={true}>Oui</option>
                                            <option value={false}>Non</option>
                                        </select>
                                        <div id="end" className="form-text">Le concierge peut-il déplacer
                                            l'intervention.
                                        </div>
                                    </div>
                                </div>
                                <div className={"mb-3 col-6 col-md-6"}>
                                    <div className={"mb-3"}>
                                        <label htmlFor="date.movable" className="form-label">Intervalle de
                                            déplacement <small></small></label>
                                        <input type="number" className="form-control"
                                            id="date.movableRange" name="date.movableRange"
                                            min={0}
                                            defaultValue={props.item.date.movableRange}
                                            aria-describedby="end" />
                                        <div id="end" className="form-text">
                                            (Nécessaire uniquement si l'intervention est déplaçable)
                                            Nombre de jours sur les quels le concierge peut déplacer l'intervention
                                            avant ou après la date initialement prévue.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label className="form-label">Concierges</label>
                                <div className={"input-group mb-3"}>
                                    <select className="form-select "
                                        id={"people.people"} name={"people.people"}
                                        defaultValue={""}>
                                        <option value={""} />
                                        <optgroup label="Concierges">
                                            {
                                                context.data.people.Concierge.map((concierge) => {
                                                    let disabled = concierge.contract.finished ?
                                                        new Date(concierge.contract.finished) >= new Date(option)
                                                        : false
                                                    if (concierge.archive) return null
                                                    if (element.list.some(el => el.people === concierge.id)) disabled = true
                                                    return (

                                                        <option value={concierge.id}
                                                            disabled={disabled}>
                                                            {concierge.lastName} {concierge.name}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </optgroup>

                                    </select>
                                    <button className={"input-group-text"} type={"button"} onClick={() => {
                                        if (!document.querySelector('select[id="people.people"]').value) return null
                                        const people = document.querySelector('select[id="people.people"]').value
                                        if (people && !element.list.some(el => el.people === people)) {

                                            setCurrentIntervenants([...currentIntervenants, { people: people }])

                                        }
                                    }}><BsPlus /></button>
                                </div>
                                <div className={"mb-3"}>
                                    <h5>Liste des intervenants</h5>
                                    <ul className={"list-group"}>


                                        {currentIntervenants.map((intervenant) => {
                                            const people = context.data.people.Concierge.find((el) => el.id === intervenant.people);
                                            return (
                                                <li
                                                    key={intervenant.people}
                                                    className="list-group-item list-group-item-action d-flex justify-content-between"
                                                    onClick={() => handleIntervenantClick(intervenant)}
                                                >
                                                    {people.lastName} {people.name}
                                                    <span className=""><small>Cliquez pour supprimer</small></span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-12">
                                    <SendButton type={"submit"} className={"btn btn-light w-100"}
                                        loading={loading}>Envoyer</SendButton>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
    // on utilise un handleclick ici car le filter au click n'est anormalement pas pris en compte
    if (props.item.choices === "InterventionModify") {
        // Handle click on intervenant
        const handleIntervenantClick = (intervenant) => {
            const newIntervenants = currentIntervenants.filter((i) => i.people !== intervenant.people);
            setCurrentIntervenants(newIntervenants);
            const newElement = { ...element, list: newIntervenants };
            setElement(newElement);
        };
        if (element.list.length === 0 && props.item.people.length > 0) {
            const objTemp = element
            objTemp.list = props.item.people
            setElement(objTemp)
        }

        if (element.list[0]?.people !== props.item.people[0]?.people) {

            const objTemp2 = element
            objTemp2.list = props.item.people
            setElement(objTemp2)
        }

        return (
            <div className={"overlay-modal"}>
                <div onClick={close} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col-8"}>
                                <h2 className={""}>Modifier l'intervention {formatDate(new Date(props.item.date.dateStart).toISOString())} </h2>
                                <p className={"form-text light"}>{props.item.id}</p>
                            </div>
                            <div className={"col-4 d-flex justify-content-end align-items-center"}>
                                <button className={"btn btn-danger"} onClick={() => {
                                    context.setError({
                                        level: 'danger',
                                        message: "Êtes vous sur de vouloir annuler l'intervention ?",
                                        btn1Text: 'Revenir',
                                        btn2Text: 'Annuler l\'intervention',
                                        btn2ActionAvoidClose: true,
                                        btn2Action: () => {
                                            context.setError({
                                                level: 'danger',
                                                message: "Confirmer l'annulation' de l'intervention du " + new Date(props.item.date.dateStart).toLocaleString() + " ?",
                                                btn1Text: 'Revenir',
                                                btn2Text: 'Annuler l\'intervention',
                                                btn2Action: () => {
                                                    context.apiReducer('modifyIntervention', {
                                                        date: { dateStart: props.item.date.dateStart },
                                                        cancelled: true
                                                    }, { id: props.item.id })
                                                        .then(() => {
                                                            props.edit({ choices: "InterventionModifySuccess" })
                                                        })
                                                        .catch(e => {
                                                            console.log(e)
                                                        })
                                                }
                                            })
                                        }
                                    })
                                }}>
                                    <BsFillTrashFill />
                                </button>
                            </div>
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            let object = {}
                            for (const input of e.target) {
                                if (input.value) {
                                    const keys = input.name.split(".").reverse()
                                    let entry = { [keys[0]]: input.value }
                                    keys.shift()
                                    for (const key of keys) {
                                        entry = { [key]: entry }
                                    }
                                    object = mergeDeep(object, entry)
                                }
                            }
                            object.people = currentIntervenants;
                            setLoading(true)
                            context.apiReducer('modifyIntervention', object, { id: props.item.id })
                                .then(() => {
                                    setLoading(false)
                                    props.edit({ choices: "InterventionModifySuccess" })
                                })
                                .catch(() => {
                                    setLoading(false)
                                })
                        }}>
                            <div className="mb-3">
                                <label htmlFor="description.description" className="form-label">Description</label>
                                <textarea className="form-control" id="description.description"
                                    name="description.description"
                                    defaultValue={props.item.description.description}
                                    rows={
                                        props.item.description.description ?
                                            props.item.description.description.split("\n").length < 3 ?
                                                3
                                                : props.item.description.description.split("\n").length
                                            : 3
                                    }
                                    aria-describedby="start" />
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="date.dateStart" className="form-label">Date</label>
                                    <input type="datetime-local" className="form-control"
                                        step={900}
                                        id="date.dateStart" name="date.dateStart"
                                        defaultValue={toInputDate(props.item.date.dateStart, 1)}
                                        aria-describedby="start" required />
                                    <div id="start" className="form-text">Date et heure de début d'intervention.
                                    </div>
                                </div>
                                <div className="mb-3 col-6 col-md-6">
                                    <label htmlFor="date.duration" className="form-label">Durée</label>
                                    <input type="time" className="form-control"
                                        id="date.duration" name="date.duration"
                                        defaultValue={props.item.date.duration}
                                        aria-describedby="end" required />
                                    <div id="end" className="form-text">Durée en heures et en minutes de
                                        l'intervention.
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"mb-3 col-6 col-md-6"}>
                                    <div className={"mb-3"}>
                                        <label htmlFor="date.movable" className="form-label">Déplacable ?</label>
                                        <select className="form-select" id={"date.movable"} name={"date.movable"}
                                            defaultValue={props.item.date.movable} required>
                                            <option value={""} selected />
                                            <option value={true}>Oui</option>
                                            <option value={false}>Non</option>
                                        </select>
                                        <div id="end" className="form-text">Le concierge peut-il déplacer
                                            l'intervention.
                                        </div>
                                    </div>
                                </div>
                                <div className={"mb-3 col-6 col-md-6"}>
                                    <div className={"mb-3"}>
                                        <label htmlFor="date.movable" className="form-label">Intervalle de
                                            déplacement <small></small></label>
                                        <input type="number" className="form-control"
                                            id="date.movableRange" name="date.movableRange"
                                            min={0}
                                            defaultValue={props.item.date.movableRange}
                                            aria-describedby="end" />
                                        <div id="end" className="form-text">
                                            (Nécessaire uniquement si l'intervention est déplaçable)
                                            Nombre de jours sur les quels le concierge peut déplacer l'intervention
                                            avant ou après la date initialement prévue.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {
                                    props.item.type === "I" ?
                                        <div>
                                            <label class="form-label">Equipe</label>
                                            <div className={"input-group w-100 mb-3"}>
                                                <select className={"form-select"}
                                                    id={"people.people"} name={"people.people"}
                                                    defaultValue={""}>
                                                    <option value="" selected="" />
                                                    <optgroup label="Equipe">
                                                        {
                                                            context.data.people.Equipe.map((equipe) => {
                                                                let disabled = !!equipe.archive
                                                                if (equipe.archive) return null
                                                                return (
                                                                    <option value={equipe.id}
                                                                        disabled={disabled}>
                                                                        {equipe.lastName} {equipe.name}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </optgroup>
                                                </select>
                                                <button className={"input-group-text"} type={"button"}
                                                    onClick={() => {
                                                        if (!document.querySelector('select[id="people.people"]').value) return null
                                                        const people = document.querySelector('select[id="people.people"]').value
                                                        if (people && !element.list.some(el => el.people === people)) {
                                                            const object = element
                                                            object.list.push({ people: people })
                                                            document.querySelector('select[id="people.people"]').value = ""
                                                            setElement(object)
                                                            refresh()
                                                        }
                                                    }}><BsPlus /></button>
                                            </div>
                                            <div className={"mb-3"}>
                                                <h5>Liste des intervenants</h5>
                                                <ul className={"list-group"}>
                                                    {
                                                        element.list.map((item) => {
                                                            const people = context.data.people.Equipe.find(el => el.id === item.people)
                                                            return <li
                                                                className={"list-group-item list-group-item-action d-flex justify-content-between"}
                                                                onClick={() => {
                                                                    const object = element
                                                                    object.list = object.list.filter(el => el.people !== item.people)
                                                                    setElement(object)
                                                                    refresh()
                                                                }}>
                                                                {people.lastName} {people.name}
                                                                <span
                                                                    className={""}><small>Cliquez pour supprimer</small></span>
                                                            </li>

                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <label className="form-label">Concierges</label>
                                            <div className={"input-group mb-3 w-100"}>
                                                <select className="form-select "
                                                    id={"people.people"} name={"people.people"}
                                                    defaultValue={""}>
                                                    <option value={""} />
                                                    <optgroup label="Concierges">
                                                        {
                                                            context.data.people.Concierge.map((concierge) => {
                                                                let disabled = concierge.contract.finished ?
                                                                    new Date(concierge.contract.finished) >= new Date(option)
                                                                    : false
                                                                if (element.list.some(el => el.people === concierge.id)) disabled = true
                                                                if (concierge.archive) return null
                                                                return (

                                                                    <option value={concierge.id}
                                                                        disabled={disabled}>
                                                                        {concierge.lastName} {concierge.name}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </optgroup>

                                                </select>
                                                <button className={"input-group-text"} type={"button"}

                                                    onClick={() => {
                                                        if (!document.querySelector('select[id="people.people"]').value) return null
                                                        const people = document.querySelector('select[id="people.people"]').value
                                                        if (people && !currentIntervenants.some(el => el.people === people)) {
                                                            setCurrentIntervenants([...currentIntervenants, { people: people }])

                                                        }
                                                    }}><BsPlus /></button>
                                            </div>
                                            <div className={"mb-3"}>
                                                <h5>Liste des intervenants</h5>
                                                <ul className={"list-group"}>
                                                    {currentIntervenants.map((intervenant) => {
                                                        const people = context.data.people.Concierge.find((el) => el.id === intervenant.people);
                                                        return (
                                                            <li
                                                                key={intervenant.people}
                                                                className="list-group-item list-group-item-action d-flex justify-content-between"
                                                                onClick={() => handleIntervenantClick(intervenant)}>
                                                                {people.lastName} {people.name}
                                                                <span className=""><small>Cliquez pour supprimer</small></span>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                }
                            </div>
                            <div className={"row"}>
                                <div className="mb-3 col-12">
                                    <SendButton type={"submit"} className={"btn btn-light w-100"}
                                        loading={loading}>Envoyer</SendButton>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "InterventionSuccess") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Votre intervention a bien été ajouté</h2>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="mb-3 col-12">
                                <button type={"button"} onClick={() => {
                                    props.edit({ choices: "" })
                                }} className={"btn btn-light w-100"}>Retour au planning
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "InterventionModifySuccess") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Votre intervention a bien été Modifiée</h2>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="mb-3 col-12">
                                <button type={"button"} onClick={() => {
                                    props.edit({ choices: "" })
                                }} className={"btn btn-light w-100"}>Retour au planning
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "InterventionMultipleModifySuccess") {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Le groupe d'intervention a bien été mis à jour</h2>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="mb-3 col-12">
                                <button type={"button"} onClick={() => {
                                    props.edit({ choices: "" })
                                }} className={"btn btn-light w-100"}>Retour au planning
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (props.item.choices === "InterventionAddValidation") {

        if (element.list.length === 0 && props.item.people.length > 0) {
            const objTemp = element
            objTemp.list = props.item.people
            setElement(objTemp)
        }

        return (
            <div className={"overlay-modal"}>
                <div onClick={close} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div>
                                <h2>Valider l'intervention à la place
                                    de {option.concierge.lastName} {option.concierge.name} </h2>
                                <p className={"form-text light"}>{formatDate(new Date(props.item.date.dateStart).toISOString())} {props.item.id}</p>
                            </div>
                        </div>
                        <ValidateIntervention send={(payload) => {
                            const fd = new FormData()
                            for (const item of payload.list) {
                                fd.append('files', item);
                            }
                            fd.append('interventionId', option.interventionId)
                            fd.append('peopleId', option.peopleId)
                            fd.append('report', payload.report)
                            context.apiReducer('ValidateInterventionConcierge', fd, {})
                                .then((response) => {
                                    props.edit({ choices: "InterventionModifySuccess" })
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }} />
                    </div>
                </div>
            </div>
        )
    }


    if (props.item.choices) {
        closeProcess()
    }

    return null
}