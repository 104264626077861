import React, { useState } from "react";
import UserContext from '../dataContext'
import GestionFichesHome from "../Components/Gestion/Fiches Home/GestionFichesHome";
import '../assets/scss/gestion.scss'
import Spinner from '../Components/Spinner';

export default function Gestion() {
    const userContext = React.useContext(UserContext)
    const [Csv, setCsv] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    if (userContext.loading) {
        return (
            <div className={"col cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
                <div className={"row justify-content-between"}>
                    <div>
                        <h1 className="blue fw-bold text-center">Gestion</h1>
                    </div>
                </div>
                <Spinner strokeWidth={9} width={40} color={"#003952"} />
                <h2 className="text-center">Chargement...</h2>
            </div>
        )
    } else if (!userContext.loading) {
        return (
            <>
                <div>
                    <div className={"row justify-content-center"}>
                        <div className="">
                            <h1 className="blue fw-bold text-center">Gestion</h1>
                            <div className={"w-48 cardsContainer cardShadow mx-auto p-2 mt-5"}>
                                <div className={"row justify-content-between gap-2"}>
                                    <div className="row flex-column align-items-center text-center">
                                        <h2 className="blue fw-bold">Facturation - produits supplémentaires</h2>
                                        <p className="black"><strong>Envoyez un fichier .csv de la liste des produits pour la facturation</strong></p>
                                        <form className="row flex-column mt-4 w-auto" onSubmit={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            setIsLoading(true)
                                            // Read the file and parse it to send it as a stringified JSON object
                                            let reader = new FileReader()
                                            reader.onload = function (event) {
                                                let fileContent = event.target.result;
                                                const csvString = csvJSONString(fileContent)
                                                const fd = new FormData()
                                                fd.append('products', csvString)
                                                userContext.apiReducer('setProductsList', fd, {})
                                                    .then((response) => {
                                                        setIsLoading(false)
                                                    })
                                                    .catch((err) => {
                                                        console.log(err)
                                                        setIsLoading(false)
                                                    })
                                            };
                                            reader.readAsText(Csv[0]);
                                        }}>
                                            <input type="file" accept=".csv" className="form-control-file" name="products" id="products" onChange={(e) => {
                                                setCsv(e.target.files)
                                            }} />
                                            <button type="submit" className="btn btn-primary mt-4">{isLoading ? <div className="spinner-border text-light" role="status"></div> : 'Envoyer'}</button>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={"w-full cardsContainer cardShadow mx-1 p-2 mt-5"}>
                        <div className={"row justify-content-between gap-2"}>
                            <div className="row flex-column align-items-center text-center">
                                <h2 className="blue fw-bold">Modification de la stucture des fiches Home</h2>
                                <GestionFichesHome context={userContext} />
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }

    else { console.log("exeption") }
}


// Hi someone from the future where this breaks because the person uploading doesnt have commas as separators!
// You could add a dropdown where the person select their separator!
// Seems easy enough from here.... though i won't do it myself i have more stuff to do lol

function csvJSONString(csv) {

    let lines = csv.split("\n");

    let result = [];

    // NOTE: If your columns contain commas in their values, you'll need
    // to deal with those before doing the next step 
    // (you might convert them to &&& or something, then covert them back later)
    let headers = lines[0].split(",");

    for (let i = 1; i < lines.length; i++) {

        let obj = {};
        let currentline = lines[i].split(",");

        for (let j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentline[j];
        }

        result.push(obj);

    }

    return JSON.stringify(result); //JSON
}