export default function ProvidersAdd({ propsContext, refresh, props, BsFillTrashFill, mergeDeep, SendButton, setElement, setOption, loading, setLoading, close, obj }) {
    const context = propsContext;

    return (
        <div className={"overlay-modal"}>
            <div onClick={close} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                    <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                        <div className={"col-6"}>
                            <h2 className={""}>{props.item.name}</h2>
                            <p className={"form-text light"}>Modifier les informations nécessaires</p>
                        </div>
                        <div className={"col-6 d-flex justify-content-end align-items-start"}>
                            <button className={"btn btn-danger mt-2"} onClick={() => {
                                context.setError({
                                    level: 'danger',
                                    message: `Êtes vous sur de vouloir supprimer le prestataire : ${props.item.name} ?`,
                                    btn1Text: 'Revenir',
                                    btn2Text: 'Supprimer',
                                    btn2Action: () => {
                                        context.apiReducer('modifyProvider', { id: props.item.id, archive: true })
                                            .then(() => {
                                                const object = props.item
                                                object.choices = "ProvidersDeleteSuccess"
                                                props.edit(object)
                                                refresh()
                                            })
                                    }
                                })
                            }}>
                                <BsFillTrashFill /> Archiver le prestataire
                            </button>
                        </div>
                    </div>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        let object = {};
                        const reviewKeys = ["client", "delay", "quality", "prices", "relation"];
                        for (const input of e.target) {
                            if (input.value) {
                                const keys = input.name.split(".").reverse();
                                let entry;
                                if (reviewKeys.includes(keys[0])) {
                                    const reviewKey = keys[0];
                                    const reviewType = keys[1];
                                    if (!object.reviews) {
                                        object.reviews = {};
                                    }
                                    if (!object.reviews[reviewKey]) {
                                        object.reviews[reviewKey] = {};
                                    }
                                    object.reviews[reviewKey][reviewType] = input.value;
                                } else {
                                    entry = { [keys[0]]: input.value };
                                    keys.shift();
                                    for (const key of keys) {
                                        entry = { [key]: entry };
                                    }
                                    object = mergeDeep(object, entry);
                                }
                            }
                        }
                        object.id = props.item.id;
                        setLoading(true)
                        // console.log(object, "object")
                        context.apiReducer('addProvider', object, { name: obj })
                            .then(() => {
                                setElement({ list: [] })
                                setOption(null)
                                setLoading(false)
                                props.edit({ ...object, choices: "ProvidersAddSuccess" })
                            })
                            .catch(() => {
                                setLoading(false)
                            })
                    }}>
                        <div className={"row"}>
                            <div className="mb-3 col-6 col-md-6">
                                <label htmlFor="lastName" className="form-label">Nom société</label>
                                <input type="text" className="form-control"
                                    id="company" name="company"
                                    defaultValue={""}
                                    aria-describedby="start" />
                            </div>
                            <div className="mb-3 col-6 col-md-6">
                                <label htmlFor="companyId" className="form-label">Siret</label>
                                <input type="text" className="form-control"
                                    id="companyId" name="companyId"
                                    defaultValue={""}
                                    aria-describedby="end" />
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="col-6 col-md-6">
                                <div className={"mb-3"}>
                                    <label htmlFor="phone" className="form-label">Téléphone</label>
                                    <input type="phone" className="form-control"
                                        id="phone" name="phone"
                                        defaultValue={""}
                                        aria-describedby="end" />
                                </div>
                            </div>
                            <div className="col-6 col-md-6">
                                <div className={"mb-3"}>
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="email" className="form-control"
                                        id="email" name="email"
                                        defaultValue={""}
                                        aria-describedby="end" />
                                </div>
                            </div>
                            <div className="">
                                <div className={"mb-3 row"}>
                                    <div className='col-6 col-md-6'>
                                        <label htmlFor="contactFirstName" className="form-label">Prénom(s) du/des contacts</label>
                                        <input type="text" className="form-control"
                                            id="contactFirstName" name="contactFirstName"
                                            defaultValue={""} />
                                    </div>
                                    <div className='col-6 col-md-6'>
                                        <label htmlFor="contactLastName" className="form-label">Nom(s) du/des contacts</label>
                                        <input type="text" className="form-control"
                                            id="contactLastName" name="contactLastName"
                                            defaultValue={""} />
                                    </div>

                                </div>
                            </div>
                            <div className="col-6 col-md-6">
                                <div className={"mb-3"}>
                                    <label htmlFor="pricing" className="form-label">Prix</label>
                                    <input type="pricing" className="form-control"
                                        id="pricing" name="pricing"
                                        defaultValue={""}
                                        aria-describedby="end" />
                                </div>
                            </div>
                            <div className={"col-6 col-md-6 mb-3"}>
                                <label htmlFor="paymentMethod" className="form-label">Moyen de règlement</label>
                                <input type="paymentMethod" className="form-control"
                                    id="paymentMethod" name="paymentMethod"
                                    defaultValue={""}
                                    aria-describedby="end" />
                            </div>
                        </div>
                        <div className={"row"}>
                            <h3>Commission</h3>
                            <div className="col-6 col-md-6">
                                <div className={"mb-3"}>
                                    <label htmlFor="commission.value" className="form-label">Valeur</label>
                                    <input type="number" className="form-control"
                                        id="commission.value" name="commission.value"
                                        min={0} defaultValue={""}
                                        aria-describedby="end" />
                                </div>

                            </div>
                            <div className="col-6 col-md-6">
                                <div className={"mb-3"}>
                                    <label htmlFor="commission.type" className="form-label">Type</label>
                                    <select className="form-select"
                                        id="commission.type" name="commission.type"
                                        defaultValue={""} aria-describedby="end">
                                        <option>€</option>
                                        <option>%</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <h3>Activité</h3>
                            <div className={"mb-3"}>
                                <input type="text" className="form-control" defaultValue={""} name='activity' />
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"mb-3"}>
                                <h3>Adresse de facturation</h3>
                                <div className="mb-3">
                                    <textarea className="form-control" defaultValue={""} name='address' />
                                </div>
                            </div>
                        </div>
                        <div className={"row reviews"}>
                            <h3>Avis</h3>
                            <div className={"mb-3"}>
                                <div className='row'>
                                    <label htmlFor="rating" className="form-label">Qualité</label>
                                    <div className='col-6' style={{ display: "flex", flexDirection: 'column' }}>
                                        <input
                                            name="reviews.quality.note"
                                            className="rating"
                                            max="5"
                                            onChange={(e) => {
                                                e.target.style.setProperty('--value', `${e.target.value}`)
                                                e.target.value = e.target.value
                                            }}
                                            step="0.5"
                                            style={{ "--value": "" }}
                                            type="range"
                                            defaultValue={""}
                                        />
                                    </div>
                                    <div className='col-6 h-full'>
                                        <textarea className="form-control" defaultValue={""} name='reviews.quality.comments' placeholder='Commentaires' />
                                    </div>
                                </div>

                                <div className='row'>
                                    <label htmlFor="rating" className="form-label">Relation avec MYPacôme</label>
                                    <div className='col-6' style={{ display: "flex", flexDirection: 'column' }}>
                                        <input
                                            name="reviews.relation.note"
                                            className="rating"
                                            max="5"
                                            onChange={(e) => {
                                                e.target.style.setProperty('--value', `${e.target.value}`)
                                            }}
                                            step="0.5"
                                            style={{ "--value": "" }}
                                            type="range"
                                            defaultValue={""}
                                        />
                                    </div>
                                    <div className='col-6 h-full'>
                                        <textarea className="form-control" defaultValue={""} name='reviews.relation.comments' placeholder='Commentaires' />
                                    </div>
                                </div>

                                <div className='row'>
                                    <label htmlFor="rating" className="form-label">Réactivité</label>
                                    <div className='col-6' style={{ display: "flex", flexDirection: 'column' }}>
                                        <input
                                            name="reviews.delay.note"
                                            className="rating"
                                            max="5"
                                            onChange={(e) => {
                                                e.target.style.setProperty('--value', `${e.target.value}`)
                                            }}
                                            step="0.5"
                                            style={{ "--value": "" }}
                                            type="range"
                                            defaultValue={""}
                                        />
                                    </div>
                                    <div className='col-6 h-full'>
                                        <textarea className="form-control" defaultValue={""} name='reviews.delay.comments' placeholder='Commentaires' />
                                    </div>
                                </div>

                                <div className='row'>
                                    <label htmlFor="rating" className="form-label">Prix</label>
                                    <div className='col-6' style={{ display: "flex", flexDirection: 'column' }}>
                                        <input
                                            name="reviews.prices.note"
                                            className="rating"
                                            max="5"
                                            onChange={(e) => {
                                                e.target.style.setProperty('--value', `${e.target.value}`)
                                            }}
                                            step="0.5"
                                            style={{ "--value": "" }}
                                            type="range"
                                            defaultValue={""}
                                        />
                                    </div>
                                    <div className='col-6 h-full'>
                                        <textarea className="form-control" defaultValue={""} name='reviews.prices.comments' placeholder='Commentaires' />
                                    </div>
                                </div>

                                <div className='row'>
                                    <label htmlFor="rating" className="form-label">Retour client</label>
                                    <div className='col-6' style={{ display: "flex", flexDirection: 'column' }}>
                                        <input
                                            name="reviews.client.note"
                                            className="rating"
                                            max="5"
                                            onChange={(e) => {
                                                e.target.style.setProperty('--value', `${e.target.value}`)
                                            }}
                                            step="0.5"
                                            style={{ "--value": "" }}
                                            type="range"
                                            defaultValue={""}
                                        />
                                    </div>
                                    <div className='col-6 h-full'>
                                        <textarea className="form-control" defaultValue={""} name='reviews.client.comments' placeholder='Commentaires' />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="mb-3">
                            <label htmlFor="observation" className="form-label">Observations</label>
                            <textarea className="form-control" rows={3}
                                defaultValue={props.item.observation}
                                id="observation" name="observation"
                                aria-describedby="start" />
                        </div>
                        <div className={"row"}>
                            <div className="mb-3 col-12">
                                <SendButton type={"submit"} loading={loading}>Envoyer</SendButton>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    )
}
