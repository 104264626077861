import React from 'react'

// Icons
import {FaArrowLeft, FaArrowRight, BsTrash} from "react-icons/all";


export default function ImagesFits(props) {

    if (!props.data || !props.onError || !props.errorText) {
        return (
            <p>
                ERREUR
            </p>
        )
    }

    if (props.data.length === 0) {
        return (
            <p>
                {props.ErrorText}
            </p>
        )
    }

    return (
        <div className='overflow-x-scroll'>
            {props.data.map((photo, index) => {
                return (
                    <div>
                        <img key={`imagefit-${index}`} src={photo} alt={photo.toString()}
                             onLoad={(e) => {
                                 if (e.target.naturalWidth > e.target.naturalHeight) {
                                     e.target.className = "landscape"
                                 } else {
                                     e.target.className = "portrait"
                                 }
                                 e.target.width = e.target.naturalWidth
                                 e.target.parentElement.lastChild.style.width = e.target.naturalWidth
                             }}
                             onError={props.onError}
                             onClick={(e) => {
                                 console.log(e.target.parentElement.lastChild)
                                 document.getElementById(`img-overlay${index}`).style.width = 20
                                 document.getElementById(`img-overlay${index}`).style.height = 20
                                 e.target.parentElement.lastChild.width = 20
                                 e.target.parentElement.lastChild.heigh = 20
                                 if (!!props.onClick) props.onClick(photo, index)
                             }}
                        />
                        <div id={`img-overlay${index}`} className={"img-overlay"}>
                            <button type={"button"} className={"btn btn-outline-danger"}
                                    hidden={index === 0}
                                    onClick={() => {
                                        if (props.move
                                            && props.data.length > 1
                                            && index > 0
                                        ) {
                                            props.move(photo, index)
                                        }
                                    }}>
                                <FaArrowLeft/>
                            </button>
                            <button type={"button"} className={"btn btn-outline-danger"}
                                    onClick={() => {
                                        if (props.delete) {
                                            props.delete(photo, index)
                                        }
                                    }}>
                                <BsTrash/>
                            </button>
                            <button type={"button"} className={"btn btn-outline-danger"}
                                    onClick={(() => {
                                        if (props.move
                                            && props.data.length > 1
                                            && props.data.length > index
                                        ) {
                                            props.move(photo, index)
                                        }
                                    })}>
                                <FaArrowRight/>
                            </button>
                        </div>
                    </div>
                )
            })}
        </div>
    )


}